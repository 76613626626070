import './ViewDonationForm.less';
import React from 'react';
import { connect } from 'react-redux';
import { Descriptions, Modal, Button, Form } from 'antd';
import { t } from '../../../framework';

class DonationFormImpl extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderDesc() {
    const items = [];
    const { metadata } = this.props;
    const { getFieldValue, getFieldProps } = this.props.form;

    if (!metadata) { return; }
    let i = 0;
    Object.keys(metadata).forEach((colkey) => {
      i++;
      const col = metadata[colkey];
      if (
        col.key !== 'updatedAt' &&
        col.key !== 'language' &&
        col.key !== 'conf_personData_content_full' 
      ) {
        getFieldProps(colkey);
        if (
          col.key === 'dpa_isDiffRecipientName' ||
          col.key === 'conf_isAck_govGrant' ||
          col.key === 'conf_isAck_condition' ||
          col.key === 'conf_isAck_personData' 
        ) {
          items.push((
            <Descriptions.Item label={t(col.label)} key={i}>
              {getFieldValue(col.key)?'Yes':'No'}
            </Descriptions.Item>
          ));
        } else if (
          col.key === 'createdAt' ||
          col.key === 'dpa_donorAckOption' ||
          col.key === 'conf_govGrant_content' ||
          col.key === 'conf_condition_content' ||
          col.key === 'conf_personData_content' 
        ) {
        } else {
          items.push((
            <Descriptions.Item label={t(col.label)} key={i}>
              {
                col.key==='di_donationAmount' || col.key==='payment_amount' 
                ? 
                getFieldValue(col.key) ? getFieldValue(col.key).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
                : 
                getFieldValue(col.key)
              }
            </Descriptions.Item>
          ));
        }
      }
    });
    return items;
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { metadata, visible, onCancel } = this.props;
    const { getFieldValue } = this.props.form;

    if (!metadata) { return; }
    var refId;
    Object.keys(metadata).forEach((colkey) => {
      const col = metadata[colkey];
      if (col.key === 'donation_id' ) {
        refId = getFieldValue(colkey);
      }
    });

    return (
      <Modal title={/*t('general.view') + ' ' + t(functionLabel)*/'Online Donation Form (Reference ID: ' + refId + ')'}
        width={1000}
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>{t('general.close')}</Button>
        ]}>
        <Form {...formItemLayout} labelAlign="left">
          <Descriptions bordered column={2}>
            {this.renderDesc()}
          </Descriptions>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const entityName = state.dashboard.currentFunction.component_param;
  return {
    entityName: entityName, // eslint-disable-line object-shorthand
    codetable: state.codetable,
    functionLabel: state.dashboard.currentFunction.label,
    metadata: state.data.metadata[entityName],
  };
};

const DonationForm = Form.create({ name: 'entityUpdate' })(DonationFormImpl);
export default connect(mapStateToProps, null)(DonationForm);

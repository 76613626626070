// UI Label (en_US)
const label = {
  'home.browser.title': 'AADO Online Donation Administration',

  'general.copyright': 'Copyright ©2021 The Hong Kong Polytechnic University',

  'general.loading': 'Loading',
  'general.search': 'Search',
  'general.reset': 'Reset',
  'general.edit': 'Edit',
  'general.delete': 'Delete',  
  'general.cancel': 'Cancel',
  'general.confirmDel': 'Confirm to delete',
  'general.confirm': 'Confirm',
  'general.add': 'Add',
  'general.logout': 'Logout',
  'general.eng': 'English',
  'general.chiTrad': '中文(繁體)',
  'general.chiSimp': '中文(简体)',

  'general.createSuc': 'Create Success',
  'general.updateSuc': 'Update Success',
  'general.deleteSuc': 'Delete Success',
  'general.refreshSuc': 'Refresh Success',
  'general.emailSuc': 'Send Email Success',
  'general.inputKeyword': 'Input Keyword',
  'general.name': 'Name',

  'general.createdAt': 'Create Time',
  'general.updatedAt': 'Update Time',
  'general.createdBy': 'Created By',
  'general.updatedBy': 'Updated By',

  'general.from': 'From',
  'general.to': 'To',

  'general.upload': 'Upload',
  'general.exportXls': 'Export Excel',
  'general.view': 'View',
  'general.close': 'Close',
  'general.refresh': 'Refresh',
  'general.sendEmail': 'Resend Email',

  'user.email': 'Email',
  'user.password': 'Password',
  'user.netId': 'Net ID',
  'user.expiryDate': 'Expiry Date',
  'user.roles': 'Roles',

  'function.labelID': 'Label ID',
  'function.icon': 'Icon',
  'function.component': 'Component',
  'function.param': 'Parameter',
  'function.fnGrp': 'Function Group',
  'function.permission': 'Permission',
  'function.action': 'Action',
  'function.seq': 'Sequence',

  'entityattr.entity': 'Entity',
  'entityattr.type': 'Type',
  'entityattr.sortable': 'Sortable',
  'entityattr.searchable': 'Searchable',
  'entityattr.editable': 'Editable',
  'entityattr.mandatory': 'Mandatory',
  'entityattr.length': 'Length',

  'login.emailLogin': 'Email Login',
  'login.loginUsingNetID': 'Login using NetID',
  'login.ForgetPass': 'Forget Password',
  'login.register': 'Register',
  'login.aadoAdmin': 'AADO Online Donation Administration',
  'login.version': 'Version v2021.06.16',
  'login.orLoginWithEmail': 'or Login with Email',

  'reg.confPass': 'Confirm Password',
  'reg.othersCallU': 'What do you want others to call you?',
  'reg.back': 'Back',
  'reg.iHaveReadThe': 'I have read the ',
  'reg.agreement': 'agreement',
  'reg.userReg': 'User Registration',
  'reg.regSuc': 'Register Success',

  'decode.decodeID': 'Decode ID',
  'decode.dType': 'Type',
  'decode.dKey': 'Key',
  'decode.dCode': 'Code',
  'decode.dValue': 'Value',
  'decode.desc': 'Description',
  'decode.seq': 'Sequence',
  'decode.parentDecodeID': 'Parent Decode ID',
  'decode.effFrom': 'Effective From',
  'decode.effTo': 'Effective To',
  'decode.IsActive': 'Is Active',

  'donation.heading.donInfo': 'Donation Information',
  'donation.heading.donPtAck': 'Donor Particulars & Acknowledgment',
  'donation.heading.donConf': 'Confirmation',  
  'donation.heading.payment': 'Payment',  

  'donation.oneOff': 'One-off',
  'donation.monthly': 'Monthly',

  'donation.donation_id': 'Donation ID',
  'donation.donationDate': 'Donation Date',
  'donation.donRecCreDate': 'Donation Record Creation Date',

  'donation.language': 'Language',

  'donation.typeOfDonation': 'Type Of Donation',  
  'donation.donationAmount': 'Donation Amount (HK$)',
  'donation.purposeOfDonation': 'Purpose of Donation',
  'donation.pod_acad': 'Purpose of Donation - Academic Development',
  'donation.pod_acad_oth': 'Purpose of Donation - Academic Development - Others',
  'donation.pod_oth': 'Purpose of Donation - Others',
  'donation.prjProgAct': 'Project/ Programme/ Activity',

  'donation.typeOfDonor': 'Type Of Donor',
  'donation.title': 'Title',
  'donation.donorName_Pry': 'Donor Name (Eng)',
  'donation.donorName_Snd': 'Donor Name (Chi)',
  'donation.orgName_Pry': 'Name of Organisation (Eng)',
  'donation.orgName_Snd': 'Name of Organisation (Chi)',
  'donation.contactPerson_Pry': 'Contact Person (Eng)',
  'donation.contactPerson_Snd': 'Contact Person (Chi)',
  'donation.organisation': 'Organisation (for individual donor)',
  'donation.position': 'Position (Individual)',
  'donation.orgPosition': 'Position (Organisation)',
  'donation.email': 'Email',
  'donation.mobile': 'Mobile',
  'donation.tel': 'Tel',
  'donation.mailAddress': 'Mailing Address',
  'donation.affiliation': 'Affiliation with PolyU (if applicable)',  
  'donation.alumni_gradYear': 'Graduation year',
  'donation.alumni_location': 'Country/ Region',
  'donation.facSchDpt': 'Faculty/ School/ Department',
  'donation.isDiffRecipientName': 'Different name on Donation Receipt',
  'donation.recipientName': 'Name on Donation Receipt',
  'donation.donorAckOption': 'Type of Donor Acknowledgement',
  'donation.donorAck_diffName': 'Donor Acknowledgement',

  'donation.conf_govGrant': 'Declaration in relation to application of Government\'s matching grant',
  'donation.conf_condition': 'Declaration in relation to condition for this donation',
  'donation.conf_personData': 'Personal Data Collection Statement',

  'payment.donation_id': 'Donation ID',
  'payment.payment_amount': 'Payment Amount (HK$)',
  'payment.status': 'Payment Status',
  'payment.txnId': 'Transaction ID',
  'payment.payment_reference_id': 'Payment Reference ID',
  'payment.payment_method_code': 'Payment Method',
  'payment.invoice_no': 'Invoice no.',
  'payment.transaction_time': 'Transaction Date and Time',
  'payment.event_id': 'Event ID',

  // Function Label
  'funcLabel.home': 'Home',
  'funcLabel.admin': 'Administration',
  'funcLabel.user': 'User',
  'funcLabel.role': 'Role',
  'funcLabel.func': 'Function',
  'funcLabel.apm': 'APM',
  'funcLabel.modelDef': 'Model Definition',
  'funcLabel.model': 'Model',
  'funcLabel.modelAttr': 'Model Attribute',
  'funcLabel.decode': 'AADO Decode',
  'funcLabel.manageDon': 'Manage Donation',
  'funcLabel.viewDon': 'View Donation',
  'funcLabel.aadoDon': 'AADO Donation',
  'funcLabel.aadoDonPay': 'AADO Payment',
  'funcLabel.don': 'Donation',

  // Error (Client Side)
  'err.client.internSys': 'Internal system error. Please contact administrator',
  'err.client.netConn': 'Network connection error. Please contact administrator',
  'err.client.emailReq': 'Email is required',
  'err.client.invEmailFormat': 'Invalid email format',
  'err.client.passReq': 'Password is required',
  'err.client.invalid': 'Invalid {%1}',
  'err.client.isReq': '{%1} is required',
  'err.client.nameReq': 'Name is required',
  'err.client.confPassReq': 'Confirm password is required',
  'err.client.plsInpEd': 'Please input expiry date',
  'err.client.passIncons': 'Two passwords that you enter is inconsistent',
  'err.client.plsAccAgree': 'Please accept agreement',
  'err.client.notAuthLogin': 'You are not authorized to login, Please contact administrator',

  // Error (Server Side)
  'err.server.emailPassReq': 'Email and password is required',
  'err.server.emailPassNotMatch': 'Email and password does not match',
  'err.server.funcNotAvail': 'System error (function not available)',
  'err.server.creRecErr': 'Create record error',
  'err.server.delRecErr': 'Delete record error',
  'err.server.updRecErr': 'Update record error',
  'err.server.lstRecErr': 'List record error',
  'err.server.sendEmailErr': 'Send email error',
  'err.server.recNotExist': 'Record does not exist',
  'err.server.recUpdByOth': 'Record updated by others',
  'err.server.notAuthLogin': 'You are not authorized to login, Please contact administrator',
  'err.server.csrfErr': 'Session is invalid (csrf error), Please login again',
  'err.server.timeOut': 'Session is invalid or timed out, Please login again',
  'err.server.intSysErr': 'Internal system error. Please contact administrator',
};

export default label;

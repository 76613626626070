import React from 'react';
import ReactExport from 'react-export-excel';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import moment from 'moment';
import { DatePicker, Checkbox, Layout, Tag, Divider, Table, Typography, Icon, Input, Button, PageHeader } from 'antd';
import { entityListAction, entityCreateAction, entityUpdateAction, entityDeleteAction, entityRefreshAction, navigateAction } from '../../../redux/actions';
import { t, logger, md5 } from '../../../framework';
import ViewDonationForm from './ViewDonationForm';
import { store } from '../../../framework/_ReduxHelper';

const { Title } = Typography;
const { Content } = Layout;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Donation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],

      searchText: '',
      stDonationId: '',
      stCreatedAt: '',
      stTypeOfDonation: '0',
      stdonationAmount: null,
      stDonorNameEng: '',
      stDonorNameChi: '',

      tod1Checked: false,
      tod2Checked: false,

      stPaymentAmount: null,
      stStatus1: '',
      stStatus2: '',
      stTxnId: '',
      stPaymentMethod: '',
      stTranTime: '',

      status1Checked: false,
      status2Checked: false,

      viewFormVisible: false,
      editFormVisible: false,
      sample: "1",

      tmpDateFrom: '',
      tmpDateTo: '',
      tmpAmtFrom: '',
      tmpAmtTo: '',
      donDateFrom: '',
      donDateTo: '',
      donAmtFrom: '',
      donAmtTo: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.result === '0' && !props.data) {
      const { listRecord, entityName, csrf } = props;
      listRecord(entityName, csrf);
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && this.props.data !== prevProps.data) {
      this.setState({ dataSource: (this.props.data).filter(x => 
        (this.state.tmpDateFrom==='' || moment(x.createdAt.substring(0,19), "YYYY-MM-DDThh:mm:ss").add(8,'hours').format("YYYY-MM-DD") >= this.state.tmpDateFrom) &&
        (this.state.tmpDateTo==='' || moment(x.createdAt.substring(0,19), "YYYY-MM-DDThh:mm:ss").add(8,'hours').format("YYYY-MM-DD") <= this.state.tmpDateTo) &&
        (this.state.tmpAmtFrom==='' || Number(x.di_donationAmount) >= Number(this.state.tmpAmtFrom)) && 
        (this.state.tmpAmtTo==='' || Number(x.di_donationAmount) <= Number(this.state.tmpAmtTo))
      )});
    } else {
      this.setState();
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  genColumnSorter(dataType, key) {
    if (dataType === 'date') {
      return (a, b) => { return Date.parse(a.expiryDate) - Date.parse(b.expiryDate); };
    } else if (dataType === 'codetable') {
      return (a, b) => {
        var aValue, bValue;
        if (key === 'di_typeOfDonation') {
          aValue = (a.di_typeOfDonation === null ? '' : a.di_typeOfDonation)
          bValue = (b.di_typeOfDonation === null ? '' : b.di_typeOfDonation)
          return aValue.localeCompare(bValue);
        }
      };
    } else if (dataType === 'string' || dataType === 'email') {
      return (a, b) => {
        var aValue, bValue;
        if (key === 'email') {
          return a.email.localeCompare(b.email);
        } else if (key === 'expiryDate') {
          return a.expiryDate.localeCompare(b.expiryDate);
        } else if (key === 'label') {
          return a.label.localeCompare(b.label);
        } else if (key === 'icon') {
          return a.icon.localeCompare(b.icon);
        } else if (key === 'DECODE_ID') {
          return a.DECODE_ID.localeCompare(b.DECODE_ID);
        } else if (key === 'createdAtStr') {
          aValue = (a.createdAtStr === null ? '' : a.createdAtStr)
          bValue = (b.createdAtStr === null ? '' : b.createdAtStr)
          return aValue.localeCompare(bValue);
        } else if (key === 'di_purposeOfDonation') {
          aValue = (a.di_purposeOfDonation === null ? '' : a.di_purposeOfDonation)
          bValue = (b.di_purposeOfDonation === null ? '' : b.di_purposeOfDonation)
          return aValue.localeCompare(bValue);
        } else if (key === 'di_prjProgAct') {
          aValue = (a.di_prjProgAct === null ? '' : a.di_prjProgAct)
          bValue = (b.di_prjProgAct === null ? '' : b.di_prjProgAct)
          return aValue.localeCompare(bValue);
        } else if (key === 'dpa_typeOfDonor') {
          aValue = (a.dpa_typeOfDonor === null ? '' : a.dpa_typeOfDonor)
          bValue = (b.dpa_typeOfDonor === null ? '' : b.dpa_typeOfDonor)
          return aValue.localeCompare(bValue);
        } else if (key === 'dpa_title') {
          aValue = (a.dpa_title === null ? '' : a.dpa_title)
          bValue = (b.dpa_title === null ? '' : b.dpa_title)
          return aValue.localeCompare(bValue);
        } else if (key === 'language') {
          aValue = (a.language === null ? '' : a.language)
          bValue = (b.language === null ? '' : b.language)
          return aValue.localeCompare(bValue);
        } else if (key === 'dpa_donorName_Pry') {
          aValue = (a.dpa_donorName_Pry === null ? '' : a.dpa_donorName_Pry)
          bValue = (b.dpa_donorName_Pry === null ? '' : b.dpa_donorName_Pry)
          return aValue.localeCompare(bValue);
        } else if (key === 'dpa_donorName_Snd') {
          aValue = (a.dpa_donorName_Snd === null ? '' : a.dpa_donorName_Snd)
          bValue = (b.dpa_donorName_Snd === null ? '' : b.dpa_donorName_Snd)
          return aValue.localeCompare(bValue);
        } else if (key === 'conf_govGrant_content') {
          aValue = (a.conf_govGrant_content === null ? '' : a.conf_govGrant_content)
          bValue = (b.conf_govGrant_content === null ? '' : b.conf_govGrant_content)
          return aValue.localeCompare(bValue);
        } else if (key === 'invoice_no') {
          aValue = (a.invoice_no === null ? '' : a.invoice_no)
          bValue = (b.invoice_no === null ? '' : b.invoice_no)
          return aValue.localeCompare(bValue);
        } else if (key === 'txnId') {
          aValue = (a.txnId === null ? '' : a.txnId)
          bValue = (b.txnId === null ? '' : b.txnId)
          return aValue.localeCompare(bValue);
        } else if (key === 'payment_method_code') {
          aValue = (a.payment_method_code === null ? '' : a.payment_method_code)
          bValue = (b.payment_method_code === null ? '' : b.payment_method_code)
          return aValue.localeCompare(bValue);
        } else if (key === 'status') {
          aValue = (a.status === null ? '' : a.status)
          bValue = (b.status === null ? '' : b.status)
          return aValue.localeCompare(bValue);
        } else if (key === 'transaction_time') {
          aValue = (a.transaction_time === null ? '' : a.transaction_time)
          bValue = (b.transaction_time === null ? '' : b.transaction_time)
          return aValue.localeCompare(bValue);
        } else if (key === 'payment_reference_id') {
          aValue = (a.payment_reference_id === null ? '' : a.payment_reference_id)
          bValue = (b.payment_reference_id === null ? '' : b.payment_reference_id)
          return aValue.localeCompare(bValue);
        } else if (key === 'event_id') {
          aValue = (a.event_id === null ? '' : a.event_id)
          bValue = (b.event_id === null ? '' : b.event_id)
          return aValue.localeCompare(bValue);
        } else {
          return a.name.localeCompare(b.name);
        }
      };
    } else if (dataType === 'integer' || dataType === 'decimal') {
      return (a, b) => {
        if (key === 'di_donationAmount') {
          return a.di_donationAmount - b.di_donationAmount;
        } else if (key === 'donation_id') {
          return a.donation_id - b.donation_id;
        } else if (key === 'payment_amount') {
          return a.payment_amount - b.payment_amount;
        } else {
          return null;
        }
      };
    }
  };

  genColumnSearchProps(datatype, dataIndex) {
    if (dataIndex==='di_typeOfDonation') {
      return {
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            {
              <Content>
                <Checkbox 
                  checked={this.state.tod1Checked}
                  onChange={e => {
                    this.setState({tod1Checked: e.target.checked});
                  }}
                >One-off Donation</Checkbox>
                <br/>
                <Checkbox 
                  checked={this.state.tod2Checked}
                  onChange={e => {
                      this.setState({tod2Checked: e.target.checked});
                  }}
                >Monthly Donation</Checkbox>
              </Content>
            }
            <Button
              type="primary"
              onClick={() => { 
                if (this.state.tod1Checked && !this.state.tod2Checked) {
                  this.setState({ stTypeOfDonation: '1' });
                  setSelectedKeys(['One-off Donation', '单次捐款', '單次捐款']);
                } else if (!this.state.tod1Checked && this.state.tod2Checked) {
                  this.setState({ stTypeOfDonation: '2' });
                  setSelectedKeys(['Monthly Donation', '每月捐款', '每月捐款']);
                } else {
                  this.setState({ stTypeOfDonation: '0' });
                  setSelectedKeys([]);
                }
                confirm();
              }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}>
              {t('general.search')}
            </Button>
            <Button onClick={
              () => {
                this.setState({ tod1Checked: false });
                this.setState({ tod2Checked: false });
                this.setState({ stTypeOfDonation: '0' });
                setSelectedKeys([]);
                clearFilters();
              }} size="small" style={{ width: 90 }}>
              {t('general.reset')}
            </Button>
          </div>
        ),
        onFilter: (value, record) => {
          return (
            (
              this.state.stTypeOfDonation==='0' ||
              (this.state.stTypeOfDonation==='1' && (record.di_typeOfDonation === 'One-off Donation' || record.di_typeOfDonation === '单次捐款' || record.di_typeOfDonation === '單次捐款')) || 
              (this.state.stTypeOfDonation==='2' && (record.di_typeOfDonation === 'Monthly Donation' || record.di_typeOfDonation === '每月捐款' || record.di_typeOfDonation === '每月捐款'))
            )
            ?
            true
            :
            false
          );
        },
      }
    } else if (dataIndex==='status') {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            {
              <Content>
                <Checkbox 
                  checked={this.state.status1Checked}
                  onChange={e => 
                    {
                      this.setState({status1Checked: e.target.checked});
                      setSelectedKeys(e.target.checked ? ['Successful', selectedKeys[1]] : ['', selectedKeys[1]]);
                      if (selectedKeys===['','']) setSelectedKeys([]);
                    }
                  }
                >Successful</Checkbox>
                <br/>
                <Checkbox 
                  checked={this.state.status2Checked}
                  onChange={e => 
                    {
                      this.setState({status2Checked: e.target.checked});
                      setSelectedKeys(e.target.checked ? [selectedKeys[0], 'Unsuccessful'] : [selectedKeys[0], '']);
                      if (selectedKeys===['','']) setSelectedKeys([]);
                    }
                  }
                >Unsuccessful</Checkbox>
              </Content>
            }
            <Button
              type="primary"
              onClick={() => { 
                selectedKeys[0] && selectedKeys[0]==='Successful' ? this.setState({ stStatus1: 'Successful' }) : this.setState({ stStatus1: 'xxx' });
                selectedKeys[1] && selectedKeys[1]==='Unsuccessful' ? this.setState({ stStatus2: 'Unsuccessful' }) : this.setState({ stStatus2: 'xxx' });
                if ((!selectedKeys[0] || selectedKeys[0]==='') && (!selectedKeys[1] || selectedKeys[1]==='')) {
                  this.setState({ stStatus1: '' });
                  this.setState({ stStatus2: '' });
                  this.cancelSearch(clearFilters, dataIndex);
                } else {
                  this.handleSearch(selectedKeys, confirm, dataIndex);
                }
              }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}>
              {t('general.search')}
            </Button>
            <Button onClick={
              () => {
                this.setState({ stStatus1: '' });
                this.setState({ stStatus2: '' });
                this.setState({ status1Checked: false });
                this.setState({ status2Checked: false });
                this.cancelSearch(clearFilters, dataIndex);
              }} size="small" style={{ width: 90 }}>
              {t('general.reset')}
            </Button>
          </div>
        ),
        onFilter: (value, record) => {
          return record.status ? (record.status===value) : false;
        },
      }
    } else {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            {
              <Input
                ref={node => { this.searchInput = node }}
                placeholder={t('general.inputKeyword')}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
            }
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}>
              {t('general.search')}
            </Button>
            <Button onClick={() => this.cancelSearch(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
              {t('general.reset')}
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
          var recordValue;
          if (datatype === 'boolean') {
            recordValue = record[dataIndex] ? 'Yes' : 'No';
            return recordValue.toLowerCase().includes(value.toLowerCase());
          } else if (datatype === 'decimal') {
            recordValue = (record[dataIndex] === null) ? "" : Number(record[dataIndex]);
            return recordValue === Number(value);
          } else if (datatype === 'integer') {
            recordValue = (record[dataIndex] === null) ? "" : record[dataIndex].toString();
            return recordValue.toLowerCase().includes(value.toLowerCase());
          } else {
            recordValue = (record[dataIndex] === null) ? "" : record[dataIndex].toString();
            return recordValue.toLowerCase().includes(value.toLowerCase());
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) setTimeout(() => this.searchInput.select());
        },
        render: text => (        
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}            
          />
        ),
      }
    }
  };

  genTableDefinitions() {
    let defs = [];
    const { metadata, codetable } = this.props;
    if (!metadata) { return; }
    for (var key in metadata) {
      const col = metadata[key];
      if (
        col.key !== 'di_typeOfDonation' &&
        col.key !== 'donation_id' &&
        col.key !== 'di_donationAmount' &&
        col.key !== 'createdAtStr' &&
        col.key !== 'language' &&
        col.key !== 'dpa_donorName_Pry' &&
        col.key !== 'dpa_donorName_Snd' &&
        col.key !== 'txnId' &&
        col.key !== 'payment_method_code' &&
        col.key !== 'payment_amount' &&
        col.key !== 'status' &&
        col.key !== 'transaction_time'
      ) continue;

      let def = { key: col.key, title: t(col.label), dataIndex: col.key };
      if (col.sortable) { def.sorter = this.genColumnSorter(col.datatype, col.key); def.sortDirections = ['descend', 'ascend']; }
      if (col.searchable) { def = Object.assign(def, { ...this.genColumnSearchProps(col.datatype, col.key) }); }
      if (col.datatype === 'codetable' && col.mode === 'multiple' && codetable[col.codetable]) {
        def.render = (items) => (<span>
          {items && items.map(item => (
            <Tag
              color='blue'
              //color='#A02337'
              key={item}>
              {item}
            </Tag>)
          )}
        </span>)
      }

      if (col.datatype === 'codetable' && col.mode === 'single' && codetable[col.codetable]) {
        def.render = (item) => {
          return item && (<span>
            <Tag
              color='blue'
              //color='#A02337'
              key={item}>
              {item}
            </Tag>
          </span>);
        }
      }
      if (col.datatype === 'password') {
        def.render = (item) => {
          return item && (<span>***************</span>);
        }
      }
      if (col.datatype === 'datetime') {
        def.render = (item) => {
          return this.formatDate(new Date(Date.parse(item)));
        }
      }
      if (col.datatype === 'boolean') {
        def.render = (item) => {
          return item ? 'Yes' : 'No';
        }
      }
      defs.push(def);
    }
    defs.push({ key: 'action', title: t('function.action'), render: this.genRecordActionRender() });

    logger.getLogger('EntityDataTable').debug(defs);
    return defs;
  }

  genRecordActionRender() {
    return (text, record) => {
      return (
        <span>
          <Button
            type="link"
            onClick={() => {
              let values = {};
              const { metadata } = this.props;
              for (var colname in record) {
                if (colname === 'key' || colname === 'version') {
                } else if (!metadata[colname]) {
                  continue;
                } else if (!metadata[colname].editable) {
                  continue;
                } else if (metadata[colname].datatype === 'date') {
                  values[colname] = { value: moment(record[colname]) };
                } else if (metadata[colname].datatype === 'password') {
                  values[colname] = { value: '{md5}' + record[colname] };
                } else if (metadata[colname].datatype === 'codetable' && metadata[colname].mode === 'single') {
                  values[colname] = { value: record[colname] ? record[colname].toString() : '' };
                } else {
                  values[colname] = { value: record[colname] };
                }
              }
              logger.getLogger('EntityDataTable').debug(values);
              this.formRef.props.form.setFields(values);
              this.setState({ viewFormVisible: true, formMode: 'update' });
            }}>
            {t('general.view')}
          </Button>
          <Divider type="vertical" />
          <Button
            onClick={() => this.handleRefresh(record['donation_id'])}
          >
            {t('general.refresh')}
          </Button>
        </span>
      );
    }
  }

  handleRefresh = (donation_id) => {
    const { refreshRecord, csrf } = this.props;
    refreshRecord(donation_id, csrf);
  }

  handleCreate = () => {
    this.formRef.props.form.validateFields((err, fieldsValue) => {
      if (!err) {
        const { createRecord, metadata, entityName, csrf } = this.props;
        const values = Object.assign({}, fieldsValue);
        for (var colname in values) {
          if (!metadata[colname]) {
            continue;
          } else if (metadata[colname].datatype === 'date') {
            values[colname] = fieldsValue[colname].format('YYYY-MM-DD');
          } else if (metadata[colname].datatype === 'password') {
            values[colname] = md5.encrypt(fieldsValue[colname]);
          }
        }
        createRecord(entityName, values, csrf);
        this.setState({ viewFormVisible: false });
        this.setState({ editFormVisible: false });
      }
    });
  }

  handleUpdate = () => {
    this.formRef.props.form.validateFields((err, fieldsValue) => {
      if (!err) {
        const { updateRecord, metadata, entityName, csrf } = this.props;
        const values = Object.assign({}, fieldsValue);
        const state = store.getState();    
        for (var colname in values) {
          if (colname==='updatedBy') values[colname]=state.session.email;
          if (!metadata[colname]) {
            continue;
          } else if (metadata[colname].datatype === 'date') {
            values[colname] = fieldsValue[colname].format('YYYY-MM-DD');
          } else if (metadata[colname].datatype === 'password') {
            if (fieldsValue[colname].startsWith('{md5}')) {
              values[colname] = fieldsValue[colname].substring(5);
            } else {
              values[colname] = md5.encrypt(fieldsValue[colname]);
            }
          }
        }

        updateRecord(entityName, values.key, values, csrf);
        this.setState({ viewFormVisible: false });
        this.setState({ editFormVisible: false });
      }
    });
  }

  cancelCreateUpdate = () => {
    this.setState({ viewFormVisible: false });
    this.setState({ editFormVisible: false });
  }

  handleDelete = (recordKey) => {
    const { deleteRecord, entityName, csrf } = this.props;
    deleteRecord(entityName, recordKey, csrf);
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
    if (dataIndex==='donation_id') {
      this.setState({ stDonationId: selectedKeys[0] });
    } else if (dataIndex==='createdAt') {
      this.setState({ stCreatedAt: selectedKeys[0] });
    } else if (dataIndex==='di_donationAmount') {
      this.setState({ stdonationAmount: Number(selectedKeys[0]) });
    } else if (dataIndex==='dpa_donorName_Pry') {
      this.setState({ stDonorNameEng: selectedKeys[0] });
    } else if (dataIndex==='dpa_donorName_Snd') {
      this.setState({ stDonorNameChi: selectedKeys[0] });
    } else if (dataIndex==='payment_amount') {
      this.setState({ stPaymentAmount: Number(selectedKeys[0]) });
    } else if (dataIndex==='txnId') {
      this.setState({ stTxnId: selectedKeys[0] });
    } else if (dataIndex==='payment_method_code') {
      this.setState({ stPaymentMethod: selectedKeys[0] });
    } else if (dataIndex==='transaction_time') {
      this.setState({ stTranTime: selectedKeys[0] });
    }
  };

  handleSearchRange = () => {
    this.setState({ donDateFrom: this.state.tmpDateFrom });
    this.setState({ donDateTo: this.state.tmpDateTo });
    this.setState({ donAmtFrom: this.state.tmpAmtFrom });
    this.setState({ donAmtTo: this.state.tmpAmtTo });

    this.setState({ dataSource: (this.props.data).filter(x => 
      (this.state.tmpDateFrom==='' || moment(x.createdAt.substring(0,19), "YYYY-MM-DDThh:mm:ss").add(8,'hours').format("YYYY-MM-DD") >= this.state.tmpDateFrom) &&
      (this.state.tmpDateTo==='' || moment(x.createdAt.substring(0,19), "YYYY-MM-DDThh:mm:ss").add(8,'hours').format("YYYY-MM-DD") <= this.state.tmpDateTo) &&
      (this.state.tmpAmtFrom==='' || Number(x.di_donationAmount) >= Number(this.state.tmpAmtFrom)) && 
      (this.state.tmpAmtTo==='' || Number(x.di_donationAmount) <= Number(this.state.tmpAmtTo))
    )});

    const { listRecord, entityName, csrf } = this.props;
    listRecord(entityName, csrf, this.state);
  };

  cancelSearch = (clearFilters, dataIndex) => {
    clearFilters();
    this.setState({ searchText: '' });
    if (dataIndex==='donation_id') {
      this.setState({ stDonationId: '' });
    } else if (dataIndex==='createdAt') {
      this.setState({ stCreatedAt: '' });
    } else if (dataIndex==='di_donationAmount') {
      this.setState({ stdonationAmount: null });
    } else if (dataIndex==='dpa_donorName_Pry') {
      this.setState({ stDonorNameEng: '' });
    } else if (dataIndex==='dpa_donorName_Snd') {
      this.setState({ stDonorNameChi: '' });
    } else if (dataIndex==='payment_amount') {
      this.setState({ stPaymentAmount: null });
    } else if (dataIndex==='txnId') {
      this.setState({ stTxnId: '' });
    } else if (dataIndex==='payment_method_code') {
      this.setState({ stPaymentMethod: '' });
    } else if (dataIndex==='transaction_time') {
      this.setState({ stTranTime: '' });
    }
  };

  cancelSearchRange = () => {
    this.setState({ donDateFrom: '' });
    this.setState({ donDateTo: '' });
    this.setState({ donAmtFrom: '' });
    this.setState({ donAmtTo: '' });
    this.setState({ tmpDateFrom: '' });
    this.setState({ tmpDateTo: '' });
    this.setState({ tmpAmtFrom: '' });
    this.setState({ tmpAmtTo: '' });

    this.setState({ dataSource: this.props.data});
  };

  handleBack = () => {
    const { permissions, currentFunction, navigate } = this.props;
    let toFunctions = permissions.filter(item => item.key === currentFunction.parent_uid);
    navigate(toFunctions.length === 0 ? permissions : toFunctions[0].subfunc, false);
  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { functionLabel, functionIcon, data } = this.props;

    const label_donation_id = t('donation.donation_id');
    const label_typeOfDonation = t('donation.typeOfDonation');
    const label_donationAmount = t('donation.donationAmount');
    const label_purposeOfDonation = t('donation.purposeOfDonation');
    const label_pod_acad_opt = t('donation.pod_acad');
    const label_pod_acad_oth_val = t('donation.pod_acad_oth');
    const label_pod_oth_val = t('donation.pod_oth');
    const label_prjProgAct = t('donation.prjProgAct');

    const label_typeOfDonor = t('donation.typeOfDonor');
    const label_title = t('donation.title');
    const label_donorName_Pry = t('donation.donorName_Pry');
    const label_donorName_Snd = t('donation.donorName_Snd');
    const label_orgName_Pry = t('donation.orgName_Pry');
    const label_orgName_Snd = t('donation.orgName_Snd');
    const label_contactPerson_Pry = t('donation.contactPerson_Pry');
    const label_contactPerson_Snd = t('donation.contactPerson_Snd');

    const label_organisation = t('donation.organisation');
    const label_position = t('donation.position');
    const label_orgPosition = t('donation.orgPosition');
    const label_email = t('donation.email');
    const label_mobile = t('donation.mobile');
    const label_tel = t('donation.tel');
    const label_mailAddress = t('donation.mailAddress');

    const label_affiliation = t('donation.affiliation');
    const label_alumni_gradYear = t('donation.alumni_gradYear');
    const label_alumni_location = t('donation.alumni_location');
    const label_facSchDpt = t('donation.facSchDpt');
    const label_isDiffRecipientName = t('donation.isDiffRecipientName');
    const label_recipientName = t('donation.recipientName');
    const label_donorAckOption = t('donation.donorAckOption');
    const label_donorAck_diffName = t('donation.donorAck_diffName');

    const label_conf_govGrant = t('donation.conf_govGrant');
    const label_conf_condition = t('donation.conf_condition');
    const label_conf_personData = t('donation.conf_personData');

    const label_donationDate = t('donation.donationDate');

    const label_txnId = t('payment.txnId');
    const label_payment_method_code = t('payment.payment_method_code');
    const label_payment_amount = t('payment.payment_amount');
    const label_status = t('payment.status');
    const label_transaction_time = t('payment.transaction_time');

    return (
      <Content>
        <ViewDonationForm
          wrappedComponentRef={this.saveFormRef}
          mode={this.state.formMode}
          visible={this.state.viewFormVisible}
          onCreate={this.handleCreate}
          onUpdate={this.handleUpdate}
          onCancel={this.cancelCreateUpdate} />

        <PageHeader
          onBack={this.handleBack}
          title={<Title level={4}><Icon type={functionIcon} />&nbsp;{t(functionLabel)}</Title>}
          extra={
            <ExcelFile element={<button>{t('general.exportXls')}</button>}>
              <ExcelSheet 
                name="Donations"
                data={
                  (data || []).filter(
                    x => (
                      (this.state.donDateFrom==='' || moment(x.createdAt.substring(0,19), "YYYY-MM-DDThh:mm:ss").add(8,'hours').format("YYYY-MM-DD") >= this.state.donDateFrom) && 
                      (this.state.donDateTo==='' || moment(x.createdAt.substring(0,19), "YYYY-MM-DDThh:mm:ss").add(8,'hours').format("YYYY-MM-DD") <= this.state.donDateTo) &&
                      (this.state.donAmtFrom==='' || x.di_donationAmount >= Number(this.state.donAmtFrom)) && 
                      (this.state.donAmtTo==='' || x.di_donationAmount <= Number(this.state.donAmtTo)) &&
                      (this.state.stDonationId===null || String(x.donation_id).includes(this.state.stDonationId)) &&
                      (this.state.stCreatedAt===null || moment(x.createdAt.substring(0,19), "YYYY-MM-DDThh:mm:ss").add(8,'hours').format("YYYY-MM-DD").includes(this.state.stCreatedAt)) &&
                      (
                        this.state.stTypeOfDonation==='0' ||
                        (this.state.stTypeOfDonation==='1' && (x.di_typeOfDonation === 'One-off Donation' || x.di_typeOfDonation === '单次捐款' || x.di_typeOfDonation === '單次捐款')) || 
                        (this.state.stTypeOfDonation==='2' && (x.di_typeOfDonation === 'Monthly Donation' || x.di_typeOfDonation === '每月捐款' || x.di_typeOfDonation === '每月捐款'))
                      ) && 
                      (this.state.stdonationAmount===null || Number(x.di_donationAmount) === this.state.stdonationAmount) &&
                      (this.state.stDonorNameEng==='' || (x.dpa_donorName_Pry||'').includes(this.state.stDonorNameEng)) &&
                      (this.state.stDonorNameChi==='' || (x.dpa_donorName_Snd||'').includes(this.state.stDonorNameChi)) &&
                      (this.state.stPaymentAmount===null || Number(x.payment_amount) === this.state.stPaymentAmount) &&
                      ((x.status||'').includes(this.state.stStatus1) || (x.status||'').includes(this.state.stStatus2)) &&
                      (this.state.stTxnId==='' || (x.txnId?x.txnId.toUpperCase():''||'').includes(this.state.stTxnId.toUpperCase())) &&
                      (this.state.stPaymentMethod==='' || (x.payment_method_code?x.payment_method_code.toUpperCase():''||'').includes(this.state.stPaymentMethod.toUpperCase())) &&
                      (this.state.stTranTime==='' || (x.transaction_time||'').includes(this.state.stTranTime))
                    )
                  ) 
                }
              >
                <ExcelColumn label={label_donation_id} value="donation_id" />
                <ExcelColumn label={label_donationDate} value="createdAtStr"/>

                <ExcelColumn label={label_typeOfDonation} value="di_typeOfDonation" />
                <ExcelColumn label={label_donationAmount} value="di_donationAmount" />
                <ExcelColumn label={label_purposeOfDonation} value="di_purposeOfDonation" />
                <ExcelColumn label={label_pod_acad_opt} value="di_pod_acad_opt" />
                <ExcelColumn label={label_pod_acad_oth_val} value="di_pod_acad_oth_val" />
                <ExcelColumn label={label_pod_oth_val} value="di_pod_oth_val" />
                <ExcelColumn label={label_prjProgAct} value="di_prjProgAct" />

                <ExcelColumn label={label_typeOfDonor} value="dpa_typeOfDonor" />
                <ExcelColumn label={label_title} value="dpa_title" />
                <ExcelColumn label={label_orgName_Pry} value="dpa_orgName_Pry" />
                <ExcelColumn label={label_orgName_Snd} value="dpa_orgName_Snd" />
                <ExcelColumn label={label_donorName_Pry} value="dpa_donorName_Pry" />
                <ExcelColumn label={label_donorName_Snd} value="dpa_donorName_Snd" />
                <ExcelColumn label={label_contactPerson_Pry} value="dpa_contactPerson_Pry" />
                <ExcelColumn label={label_contactPerson_Snd} value="dpa_contactPerson_Snd" />

                <ExcelColumn label={label_organisation} value="dpa_organisation" />
                <ExcelColumn label={label_position} value="dpa_position" />
                <ExcelColumn label={label_orgPosition} value="dpa_orgPosition" />
                <ExcelColumn label={label_email} value="dpa_email" />
                <ExcelColumn label={label_mobile} value="dpa_mobile" />
                <ExcelColumn label={label_tel} value="dpa_tel" />
                <ExcelColumn label={label_mailAddress} value="dpa_mailAddress" />

                <ExcelColumn label={label_affiliation} value="dpa_affiliation" />
                <ExcelColumn label={label_alumni_gradYear} value="dpa_alumni_gradYear" />
                <ExcelColumn label={label_alumni_location} value="dpa_alumni_location" />
                <ExcelColumn label={label_facSchDpt} value="dpa_facSchDpt" />
                <ExcelColumn label={label_isDiffRecipientName} value={(col) => col.dpa_isDiffRecipientName ? "Yes" : "No"} />

                <ExcelColumn label={label_recipientName} value="dpa_recipientName" />
                <ExcelColumn label={label_donorAckOption} value="dpa_donorAckOption" />
                <ExcelColumn label={label_donorAck_diffName} value="dpa_donorAck_diffName" />

                <ExcelColumn label={label_conf_govGrant} value={(col) => col.conf_isAck_govGrant ? "Yes" : "No"} />
                <ExcelColumn label={label_conf_govGrant} value="conf_govGrant_content" />
                <ExcelColumn label={label_conf_condition} value={(col) => col.conf_isAck_condition ? "Yes" : "No"} />
                <ExcelColumn label={label_conf_condition} value="conf_condition_content" />
                <ExcelColumn label={label_conf_personData} value={(col) => col.conf_isAck_personData ? "Yes" : "No"} />
                <ExcelColumn label={label_conf_personData} value="conf_personData_content" />

                <ExcelColumn label={label_txnId} value="txnId" />
                <ExcelColumn label={label_payment_method_code} value="payment_method_code" />
                <ExcelColumn label={label_payment_amount} value="payment_amount" />
                <ExcelColumn label={label_status} value="status" />
                <ExcelColumn label={label_transaction_time} value="transaction_time" />
              </ExcelSheet>
            </ExcelFile>
          }
        />

        <table>
          <tbody>
            <tr>
              <td>
                {t('donation.donationDate')}
              </td>
              <td>
                <DatePicker 
                  placeholder={t('general.from')}
                  value={this.state.tmpDateFrom===''?null:moment(this.state.tmpDateFrom)}
                  onChange={(date, dateString) => {
                    this.setState({ tmpDateFrom: dateString });
                  }}
                />
              </td>
              <td>
                <DatePicker 
                  placeholder={t('general.to')}
                  value={this.state.tmpDateTo===''?null:moment(this.state.tmpDateTo)}
                  onChange={(date, dateString) => {
                    this.setState({ tmpDateTo: dateString });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                {t('donation.donationAmount')}&nbsp;&nbsp;
              </td>
              <td>
                <Input
                  placeholder={t('general.from')}
                  value={this.state.tmpAmtFrom}
                  onChange={e => {
                    this.setState({ tmpAmtFrom: e.target.value });
                  }}
                />
              </td>
              <td>
                <Input
                  placeholder={t('general.to')}
                  value={this.state.tmpAmtTo}
                  onChange={e => {
                    this.setState({ tmpAmtTo: e.target.value });
                  }}
                />
              </td>
              <td>
                &nbsp;&nbsp;              
                <Button
                  type="primary"                
                  onClick={() => this.handleSearchRange()}
                  icon="search"
                  size="small"
                  style={{ width: 90, marginRight: 8 }}>
                  {t('general.search')}
                </Button>              
                <Button
                  onClick={() => this.cancelSearchRange()} 
                  size="small" style={{ width: 90 }}>
                  {t('general.reset')}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <h1>
          <Table 
            columns={this.genTableDefinitions()} 
            dataSource={this.state.dataSource}
          />
        </h1>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  let entityName = state.dashboard.currentFunction.component_param;

  return {
    entityName: entityName,
    permissions: state.session.permissions,
    currentFunction: state.dashboard.currentFunction,
    functionIcon: state.dashboard.currentFunction.icon,
    functionLabel: state.dashboard.currentFunction.label,
    metadata: state.data.metadata[entityName],
    lang: state.session.lang,
    codetable: state.codetable,
    data: state.data.data,
    action: state.action.type,
    result: state.action.result,
    csrf: state.action.csrf
  };
};

const mapDispatchToProps = (dispatch) => ({
  navigate: (toFunctions) => {
    dispatch(navigateAction(toFunctions));
  },
  listRecord: async (entityName, csrf) => {
    await dispatch(entityListAction(entityName, csrf));
  },
  createRecord: (entityName, data, csrf) => {
    dispatch(entityCreateAction(entityName, data, csrf));
  },
  updateRecord: (entityName, key, data, csrf) => {
    dispatch(entityUpdateAction(entityName, key, data, csrf));
  },
  deleteRecord: (entityName, key, csrf) => {
    dispatch(entityDeleteAction(entityName, key, csrf));
  },
  refreshRecord: (donation_id, csrf) => {
    dispatch(entityRefreshAction(donation_id, csrf));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Donation);

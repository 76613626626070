import { combineReducers } from 'redux';

import action from './_action';
import data from './_data';
import codetable from './_codetable';
import session from './_session';
import dashboard from './_dashboard';

export default combineReducers({
  session, action, dashboard, data, codetable,
});

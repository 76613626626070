export default (state = {}, action) => {
  let newState = state;
  switch (action.type) {
    case 'AUTHENTICATE-SUCCESS': {
      const { codetable } = action.payload.value;
      newState = Object.assign({}, codetable);
      break;
    }
    case 'LOGOUT-SUCCESS':
      newState = {};
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

import './RegistrationForm.less';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Tooltip, Icon, Checkbox, Button, Card, message, DatePicker, Spin } from 'antd';
import Container from '../../shared/Container';
import { t, md5, logger } from '../../../framework';
import { registerAction } from '../../../redux/actions';

const { Meta } = Card;

class RegistrationFormImpl extends React.Component {

  state = {
    version: 0,
    confirmDirty: false,
    isSubmitting: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, fieldsValue) => {
      if (!err) {
        const { register, version } = this.props;
        const values = {
          ...fieldsValue,
          'expiryDate': fieldsValue['expiryDate'].format('YYYY-MM-DD'),
          'passwd': md5.encrypt(fieldsValue['password'])
        };
        delete values.confirm;
        delete values.password;
        this.setState({isSubmitting: true, version: version});
        register(values);
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  validateConfirmPassword = (rule, value, callback) => {


logger.debug("in validateConfirmPassword");


    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(t('err.client.passIncons'));
    } else {
      callback();
    }
  };

  validatePassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  static getDerivedStateFromProps(props, state) {
    if (props.version !== state.version && state.isSubmitting === true) {
      message.info(t('reg.regSuc'));
      return {...state, isSubmitting: false, version:props.version};
    } else {
      return null;
    }
  }

  render() {
    const { isSubmitting } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return (
      <Spin spinning={isSubmitting} size="large" tip={t('general.loading')}>
        <Container>
          <Card bordered={true} className="registration-form" >
            <Meta title={<span><Icon type="user"/>&nbsp;{t('reg.userReg')}</span>} className="title"/>
            <Form {...formItemLayout} onSubmit={this.handleSubmit} labelAlign="left">
              <Form.Item label={t('user.email')}>
                {getFieldDecorator(
                    'email', {rules: [{type: 'email', message: 'Invalid email address'}, {required: true, message: t('err.client.emailReq')}]}
                  )(<Input />)}
              </Form.Item>
              <Form.Item label={t('user.password')} hasFeedback>
                {getFieldDecorator(
                    'password',	{rules: [{required: true, message: t('err.client.passReq')}, {validator: this.validatePassword}]}
                  )(<Input.Password />)}
              </Form.Item>
              <Form.Item label={t('reg.confPass')} hasFeedback>
                {getFieldDecorator(
                    'confirm', {rules: [{required: true, message: t('err.client.confPassReq')}, {validator: this.validateConfirmPassword}]}
                  )(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </Form.Item>
              <Form.Item label={<span>{t('general.name')}&nbsp;<Tooltip title={t('reg.othersCallU')}><Icon type="question-circle-o" /></Tooltip></span>}>
                {getFieldDecorator(
                    'name', {rules: [{ required: true, message: t('err.client.nameReq'), whitespace: true }]}
                  )(<Input />)}
              </Form.Item>
              <Form.Item label={t('user.expiryDate')}>
                {getFieldDecorator(
                    'expiryDate', {rules: [{ type: 'object', required: true, message: t('err.client.plsInpEd') }]}
                  )(<DatePicker/>)}
              </Form.Item>
              <Form.Item {...tailFormItemLayout} className="basic-form-item"	>
                {getFieldDecorator('agreement', {rules: [{ type: 'boolean', required: true, transform: value => (value || undefined), message: t('err.client.plsAccAgree') }]})(<Checkbox>{t('reg.iHaveReadThe')} <a href="/memberagreement">{t('reg.agreement')}</a></Checkbox>)}
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="button">{t('login.register')}</Button>&nbsp;
                <Link to={'/'}><Button type="primary" className="button">{t('reg.back')}</Button></Link>
              </Form.Item>
            </Form>
          </Card>
        </Container>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    version: new Date().getTime(),
    serverErrorMsg: ((state.action.result === '' || state.action.result === '0') ? '' : t(state.action.result))
  };
};

const mapDispatchToProps = (dispatch) => ({
  register:(values) => {
    dispatch(registerAction(values));
  }
});

const RegistrationForm = Form.create({ name: 'registration' })(RegistrationFormImpl);
export default connect(mapStateToProps,mapDispatchToProps)(RegistrationForm);

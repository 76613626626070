import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Input, DatePicker, Select, Radio } from 'antd';
import { t } from '../../../framework';

const { Option } = Select;

class EntityDataFormImpl extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  getValidationRules(col) {
    const rules = [];
    if (col.datatype === 'email') {
      rules.push({ type: 'email', message: t('err.client.invalid').replace('{%1}', t(col.label)) });
    }
    if (col.mandatory) {
      rules.push({ required: true, message: t('err.client.isReq').replace('{%1}', t(col.label)) });
    }
    if (col.datatype === 'email' || col.datatype === 'string') {
      rules.push({ max: col.length, message: t(col.label) + ' must be maximum ' + col.length + ' characters.' });
    }
    if (col.datatype === 'integer') {
      //rules.push({ pattern: new RegExp('^([012]?[0-9]?[0-9]|3[0-5][0-9]|36[0-6])$'), message: t(col.label) + ' must be between 0 and 366.' });
      //rules.push({ min: -2147483648, message: t(col.label) + ' must be minimum -2147483648.' });
      //rules.push({ max: 2147483647, message: t(col.label) + ' must be maximum 2147483647.' });
    }
    return rules;
  }

  getCodeTableOptions(codetableName) {
    const { codetable } = this.props;
    if (codetable[codetableName]) {
      return codetable[codetableName].map((item) => (<Option key={item.key}>{item.name}</Option>));
    } else {
      return '';
    }
  }

  renderFormItems() {
    const items = [];
    const { metadata, functionLabel } = this.props;
    const { getFieldDecorator } = this.props.form;
    if (!metadata) { return; }
    Object.keys(metadata).forEach((colkey) => {
      const col = metadata[colkey];
      if (!col.editable) {
        return;
      } else if (
        functionLabel==='funcLabel.decode' &&
        (col.key==='password' || col.key==='confirm')
      ) {
        return;
      } else if (col.datatype === 'decimal') {
        items.push((
          <Form.Item label={t(col.label)} key={col.key}>
            {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(<Input />)}
          </Form.Item>
        ));
      } else if (col.datatype === 'boolean') {
        items.push((
          <Form.Item label={t(col.label)} key={col.key}>
            {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })
            (
              <Radio.Group >
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </Radio.Group>
            )
            }
          </Form.Item>
        ));
      } else if (col.datatype === 'date') {
        items.push((
          <Form.Item label={t(col.label)} key={col.key}>
            {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(<DatePicker />)}
          </Form.Item>
        ));
      } else if (col.datatype === 'password') {
        items.push((
          <Form.Item label={t(col.label)} key={col.key}>
            {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(<Input.Password visibilityToggle={false}/>)}
          </Form.Item>
        ));
      } else if (col.datatype === 'codetable' && col.mode === 'multiple') {
        items.push((
          <Form.Item label={t(col.label)} key={col.key}>
            {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(
              <Select mode="multiple">{this.getCodeTableOptions(col.codetable)}</Select>
            )}
          </Form.Item>
        ));
      } else if (col.datatype === 'codetable' && col.mode === 'single') {
        items.push((
          <Form.Item label={t(col.label)} key={col.key}>
            {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(
              <Select allowClear={true}>{this.getCodeTableOptions(col.codetable)}</Select>
            )}
          </Form.Item>
        ));
      } else {
        items.push((
          <Form.Item label={t(col.label)} key={col.key}>
            {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(<Input />)}
          </Form.Item>
        ));
      }
    });
    items.push((
      <Form.Item key="version">
        {getFieldDecorator('version', {})(<Input type="hidden" />)}
      </Form.Item>
    ));
    return items;
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { getFieldDecorator } = this.props.form;
    const { visible, mode, functionLabel, onCancel, onCreate, onUpdate } = this.props;
    return (
      <Modal title={(mode === 'create' ? t('general.add') : t('general.edit')) + ' ' + t(functionLabel)}
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button key="submit" type="primary" onClick={mode === 'create' ? onCreate : onUpdate}>{mode === 'create' ? t('general.add') : t('general.edit')}</Button>,
          <Button key="back" onClick={onCancel}>{t('general.cancel')}</Button>
        ]}>
        <Form {...formItemLayout} labelAlign="left">
          {getFieldDecorator('key', {})(<Input type="hidden" />)}
          {this.renderFormItems()}
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const entityName = state.dashboard.currentFunction.component_param;
  return {
    entityName: entityName, // eslint-disable-line object-shorthand
    codetable: state.codetable,
    functionLabel: state.dashboard.currentFunction.label,
    metadata: state.data.metadata[entityName],
  };
};

const EntityDataForm = Form.create({ name: 'entityUpdate' })(EntityDataFormImpl);
export default connect(mapStateToProps, null)(EntityDataForm);

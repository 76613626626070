// UI Label (zh_CN)
const label = {
  'LB0001': '载入中',
  'LB0002': '搜寻',
  'LB0003': '重设',
  'LB0004': '编辑',
  'LB0005': '删除',
  'LB0006': '取消',
  'LB0007': '确认要删除',
  'LB0008': '确认',
  'LB0009': '新增',
  'LB0010': '登出',
  'LB0011': 'English',
  'LB0012': '中文(繁體)',
  'LB0013': '新增成功',
  'LB0014': '编辑成功',
  'LB0015': '删除成功',
  'LB0016': '输入关键字',
  'LB0017': '名称',
  'LB0018': '电邮地址',
  'LB0019': '密码',
  'LB0020': '生日日期',
  'LB0021': '身份',
  'LB0022': '新增时间',
  'LB0023': '更新时间',
  'LB0024': '标签ID',
  'LB0025': '图标名称',
  'LB0026': '构件名称',
  'LB0027': '构件参数',
  'LB0028': '功能组',
  'LB0029': '棹限',
  'LB0030': '行动',
  'LB0031': '排序',
  'LB0032': '数据',
  'LB0033': '类型',
  'LB0034': '类型参数',
  'LB0035': '可排序',
  'LB0036': '可搜寻',
  'LB0037': '可编辑',
  'LB0038': '必填项',
  'LB0039': '电邮登入',
  'LB0040': 'NetID 登入',
  'LB0041': '忘记密码',
  'LB0042': '注册',
  'LB0043': 'ReactJS 参考实例',
  'LB0044': '此网页使用Ant Design React',
  'LB0045': '或使用电邮登入',
  'LB0046': '确认密码',
  'LB0047': '你希望别人怎么称呼你?',
  'LB0048': '返回',
  'LB0049': '我看过了',
  'LB0050': '协议',
  'LB0051': '用户注册',
  'LB0052': '上传',
  'LB0053': '注册成功',
  'LB0054': '中文(简体)',
  'LB0055': '解码 ID',
  'LB0056': '解码 Type',
  'LB0057': '解码 Key',
  'LB0058': '解码 Code',
  'LB0059': '解码 Value',
  'LB0060': '描述',
  'LB0061': '顺序',
  'LB0062': '母解码 ID',
  'LB0063': '有效日期(由)',
  'LB0064': '有效日期(至)',
  'LB0065': '活跃',
  'LB0066': '新增人员',
  'LB0067': '更新人员',
  'LB0068': '刷新',
  'LB0069': '捐款 ID',
  'LB0070': '捐款金額',
  'LB0071': '捐款目的',
  'LB0072': '项目/计划/活动',
  'LB0073': '捐款类型',
  'LB0074': '检视',
  'LB0075': '一次性',
  'LB0076': '每月',
  'LB0077': '關閉', 
  'LB0078': '捐赠者类别',
  'LB0079': '称谓',
  'LB0080': '姓名 (英文)',
  'LB0081': '姓名 (中文)',
  'LB0082': '机构名称 (英文)',
  'LB0083': '机构名称 (中文)',
  'LB0084': '联络人 (英文)',
  'LB0085': '联络人 (中文)',
  'LB0086': '机构',
  'LB0087': '职衔',
  'LB0088': '电邮地址',
  'LB0089': '手机',
  'LB0090': '电话',
  'LB0091': '通讯位址',
  'LB0092': '与理大关系 (如适用)',  
  'LB0093': '毕业年份',
  'LB0094': '国家/ 地区',
  'LB0095': '学院/ 学系',
  'LB0096': '捐款收据抬头 (如与捐赠者姓名不同)',
  'LB0097': '捐款收据抬头',
  'LB0098': '捐赠鸣谢',
  'LB0099': '其他芳名',
  'LB0100': '有关申请政府配对补助金计画的声明',
  'LB0101': '有关申请政府配对补助金计画的声明',
  'LB0102': '有关此捐款附带条件的声明',
  'LB0103': '有关此捐款附带条件的声明',
  'LB0104': '收集个人资料声明',
  'LB0105': '收集个人资料声明',
  'LB0106': '捐款资料',
  'LB0107': '捐赠者资料及鸣谢',
  'LB0108': '确认',
  // Function Label
  'FN0000': '主页',
  'FN0001': '管理',
  'FN0002': '用户',
  'FN0003': '身份',
  'FN0004': '功能',
  'FN0005': '系统数据',
  'FN0006': '数据定义',
  'FN0007': '数据',
  'FN0008': '数据属性',
  'FN0009': 'AADO 解码',
  'FN0010': '管理捐款',
  'FN0011': '检视捐款',
  'FN0012': 'AADO 捐款',
  'FN0013': '捐款',
  // Error (Client Side)
  'EC9999': '内部系统错误。请联系管理员',
  'EC9998': '网络连接错误。请联系管理员',
  'EC0001': '请输入电邮地址',
  'EC0002': '电邮地址格式不正确',
  'EC0003': '请输入密码',
  'EC0004': '{%1}格式不正确',
  'EC0005': '请输入{%1}',
  'EC0006': '请输入名称',
  'EC0007': '请输入确认密码',
  'EC0008': '请输入生日日期',
  'EC0009': '您输入的两个密码不一致',
  'EC0010': '请接受协议',
  'EC0011': '您没有权限登入,请联系管理员',
  // Error (Server Side)
  'E00001': '请输入电邮地址和密码',
  'E00002': '电邮地址和密码不一样',
  'E00003': '系统错误（功能不存在)',
  'E00004': '新增记录错误',
  'E00005': '删除记录错误',
  'E00006': '更新记录错误',
  'E00007': '读取记录错误',
  'E00008': '记录不存在',
  'E00009': '记录已被更改',
  'E00010': '您没有权限登入,请联系管理员',
  'E99997': '登录无效(csrf错误)，请重新登录',
  'E99998': '登录无效或超时，请重新登录',
  'E99999': '内部系统错误。请联系管理员',
};

export default label;

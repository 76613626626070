export default (state = { currentFunction: null, loadingCount: 0 }, action) => {
  let newState = state;
  switch (action.type) {
    case 'NAVIGATE':
      newState = Object.assign({}, newState, { currentFunction: action.payload.to });
      break;
    case 'DATA-LOADING-BEGIN':
      newState = Object.assign({}, newState, { loadingCount: state.loadingCount + 1 });
      break;
    case 'DATA-LOADING-END':
      newState = Object.assign({}, newState, { loadingCount: state.loadingCount - 1 });
      break;
    case 'LOGOUT-SUCCESS':
      newState = { currentFunction: null, loadingCount: state.loadingCount };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

import './Dashboard.less';
import React from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Icon, Avatar } from 'antd';
import { t } from '../../../framework';
import { logoutAction } from '../../../redux/actions';

const { SubMenu } = Menu;
const { Header } = Layout;

class TopMenu extends React.Component {

  onClick = e => {
    const { switchToEng, switchToCht, switchToChs, logout, csrf } = this.props;
    if (e.key === 'logout') {
      logout(csrf);
    } else if (e.key === 'lang-eng') {
      switchToEng();
    } else if (e.key === 'lang-cht') {
      switchToCht();
    } else if (e.key === 'lang-chs') {
      switchToChs();
    }
  }

  render() {
    return (
      <Header className="header">
        <span className="title">{t('login.aadoAdmin')}</span>
        <Menu theme="light" mode="horizontal" selectable={false} style={{ lineHeight: '64px', float: 'right' }} onClick={this.onClick}>
          {/*
          <SubMenu title={<span className="header-item"><Icon type="global" style={{ fontSize: 28, marginTop: 0, verticalAlign: 'middle', color: 'rgb(204, 204, 204)' }} /></span>}>
            <Menu.Item key="lang-eng">{t('general.eng')}</Menu.Item>
            <Menu.Item key="lang-cht">{t('general.chiTrad')}</Menu.Item>
            <Menu.Item key="lang-chs">{t('general.chiSimp')}</Menu.Item>
          </SubMenu>
          */}
          <SubMenu title={<span className="header-item"><Avatar shape="square" size={32} icon="user" /></span>}>
            <Menu.Item key="logout"><Icon type="logout" />{t('general.logout')}</Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.session.lang,
    csrf: state.action.csrf,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: (csrf) => {
    dispatch(logoutAction(csrf));
  },
  switchToEng: () => {
    dispatch({ type: 'SWITCH-LANG', payload: 'enUS' });
  },
  switchToCht: () => {
    dispatch({ type: 'SWITCH-LANG', payload: 'zhHK' });
  },
  switchToChs: () => {
    dispatch({ type: 'SWITCH-LANG', payload: 'zhCN' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);

import './LoginForm.less';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Form, /*Icon, Input,*/ Button, Card , Alert, Spin } from 'antd';

import Container from '../../shared/Container';
import { t, md5 } from '../../../framework';
import { loginAction } from '../../../redux/actions';

const { Meta } = Card;

class LoginFormImpl extends React.Component {

  state = {
    version: 0,
    clientErrorMsg: '',
    submitInProgress: false
  }

  handleSubmit = e => {
    const { form, login, version } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err && err.email) {
        this.setState({clientErrorMsg: err.email.errors[0].message});
      } else if (err && err.password) {
        this.setState({clientErrorMsg: err.password.errors[0].message});
      } else {
        this.setState({submitInProgress: true, version: version});
        login(values.email, md5.encrypt(values.password));
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.version !== state.version) {
      return {submitInProgress: false, version:props.version, clientErrorMsg:''};
    } else {
      return null;
    }
  }

  render() {
    const { clientErrorMsg, submitInProgress } = this.state;
    const { serverErrorMsg } = this.props;
    //const { getFieldDecorator } = this.props.form;
    return (
      <Spin spinning={submitInProgress} size="large" tip={t('general.loading')}>
        <Container>
          <Card bordered={true} className="login-form">
            <Meta title={t('login.aadoAdmin')} description={t('login.version')} className="title"/>
            <Link to={`/auth/adfs/login`}><Button type="primary" /*icon="windows"*/ className="adfs-button">{t('login.loginUsingNetID')}</Button></Link>
            {/*<span className="login-with-email">{t('login.orLoginWithEmail')}</span>*/}
            {(clientErrorMsg !== '') ? (<Alert type="error" message={clientErrorMsg} banner/>) : ''}
            {(clientErrorMsg === '' && serverErrorMsg !== '') ? (<Alert type="error" message={serverErrorMsg} banner/>) : ''}
            {/*
            <Form onSubmit={this.handleSubmit}>
              {getFieldDecorator('email', {
                  rules: [{ required: true, message: t('err.client.emailReq')}, { type: 'email', message: t('err.client.invEmailFormat')}]}
                )(<Input id="email" prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.5)'}} />} placeholder={t('user.email')} className="item"/>)}
              {getFieldDecorator('password', {
                  rules: [{ required: true, message: t('err.client.passReq')}]}
                )(<Input id="password" prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.5)'}} />} type="password" placeholder={t('user.password')} className="item"/>)}
              <Button type="primary" htmlType="submit" icon="database" className="button">{t('login.emailLogin')}</Button>
              <Link to={`/register`} className="register">{t('login.register')}</Link>
            </Form>
            */}
          </Card>
        </Container>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    version: new Date().getTime(),
    serverErrorMsg: ((state.action.result === '' || state.action.result === '0') ? '' : t(state.action.result))
  };
};

const mapDispatchToProps = (dispatch) => ({
  login:(email, password) => {
    dispatch(loginAction(email, password));
  }
});

const LoginForm = Form.create({ name: 'LoginForm' })(LoginFormImpl);
export default connect(mapStateToProps,mapDispatchToProps)(LoginForm);

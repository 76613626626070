import crypto from 'crypto';

const aes = {
  encrypt: (data) => {
    const cipher = crypto.createCipher('aes-256-ctr', process.env.REACT_APP_AES_SECRET);
    let crypted = cipher.update(data, 'utf8', 'hex');
    crypted += cipher.final('hex');
    return crypted;
  },
  decrypt: (data) => {
    if (!data) return '';
    const decipher = crypto.createDecipher('aes-256-ctr', process.env.REACT_APP_AES_SECRET);
    let decrypted = decipher.update(data, 'hex', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
  },
};

const md5 = {
  encrypt: (data) => {
    const hash = crypto.createHash('md5').update(data).digest('hex');
    return hash;
  },
};

export { aes, md5 };

import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

import reducers from '../redux/reducers';

const createReduxStore = () => {
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_REDUX_DEBUG === 'Y') {
    // eslint-disable-next-line no-underscore-dangle
    const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    const s = createStore(reducers, {}, compose(applyMiddleware(thunk), reduxDevTools));
    return s;
  }
  const s = createStore(reducers, {}, applyMiddleware(thunk));
  return s;
};

export const store = createReduxStore();

export const redux = {
  getStore: () => store,
};

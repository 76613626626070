import './EditDonationForm.less';

import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Input, DatePicker, Select, Radio, Checkbox, Row, Col, Descriptions } from 'antd';
import { t } from '../../../framework';
import { store } from '../../../framework/_ReduxHelper';

const { Option } = Select;

class EditDonationFormImpl extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  state = {
    podValue: 0,
    podAcadValue: 0,
    typeOfDonorValue: 0,
    donorAckValue: 0,
  };

  getValidationRules(col) {
    const rules = [];
    if (col.datatype === 'email' || col.datatype === 'string') {
      rules.push({ max: col.length, message: t(col.label) + ' must be maximum ' + col.length + ' characters.' });
    }
    return rules;
  }

  getCodeTableOptions(codetableName) {
    const state = store.getState();
    const lang = state.session.lang ? state.session.lang : 'en_US';
    const { codetable } = this.props;
    if (codetable[codetableName]) {
      return codetable[codetableName].map(
        (item) => (
          (!item.active)
          ?
          null
          :
          <Option key={item.name}>        
            {lang==='zhCN'?item.name_zhCN:lang==='zhHK'?item.name_zhHK:item.name}
          </Option>
        )
      );
    } else {
      return '';
    }
  }

  getCodeTableOptionsRadio(col, diffNameCol) {
    const state = store.getState();
    const lang = state.session.lang ? state.session.lang : 'en_US';
    const { codetable } = this.props;
    if (codetable[col.codetable]) {
      return codetable[col.codetable].map(
        (item) => (
          (!item.active)
          ?
          null
          :
          <div key={'div_'+item.key}>
            <Radio key={item.key} value={item.name}>
              {lang==='zhCN'?item.name_zhCN:lang==='zhHK'?item.name_zhHK:item.name}
              {}            
            </Radio>
            <br/>
          </div>
        )
      );
    } else {
      return '';
    }
  }

  renderFormItems() {
    const items = [];
    const { metadata } = this.props;
    const { getFieldDecorator, getFieldValue, getFieldProps, getFieldError } = this.props.form;
    const { TextArea } = Input;

    let i = 0;

    if (!metadata) { return; }
    Object.keys(metadata).forEach((colkey) => {
      i++;
      const col = metadata[colkey];
      getFieldProps(colkey);
      if (col.key==='di_typeOfDonation') {
        items.push((      
          <Col span={24} key={i}>
              <div><p style={{fontSize:16, color: "#000000", textAlign: "left", paddingTop: "0px" }}>{t('donation.heading.donInfo')}</p><br/></div>
          </Col>
        ));
        i++;
      }

      if (!col.editable) {
        return;
      } else if (col.datatype === 'boolean') {
        items.push((
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key}>
              {getFieldDecorator(col.key, { valuePropName: 'checked', initialValue: true, rules: this.getValidationRules(col) })(<Checkbox />)}
            </Form.Item>
          </Col>
        ));
      } else if (col.datatype === 'date') {
        items.push((
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key}>
              {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(<DatePicker />)}
            </Form.Item>
          </Col>
        ));
      } else if (col.datatype === 'datetime' && col.key==='createdAt') {
      } else if (col.key==='createdAtStr') {
        items.push(
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key} >
              {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(<Input disabled/>)}
            </Form.Item>
          </Col>
        );
      } else if (col.key==='language') {
        items.push((
          <Col span={12} key={i}>
            <Form.Item key="language">
              {getFieldDecorator('language', {})(<div><p style={{color: "#ffffff", paddingTop: "0px" }}>.</p><Input type="hidden" /></div>)}
            </Form.Item>
          </Col>
        ));
      } else if (col.datatype === 'password') {
        items.push((
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key}>
              {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(<Input.Password visibilityToggle={false} />)}
            </Form.Item>
          </Col>
        ));
      } else if (col.datatype === 'codetable' && col.mode === 'multiple') {
        items.push((
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key}>
              {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(
                <Select mode="multiple">{this.getCodeTableOptions(col.codetable)}</Select>
              )}
            </Form.Item>
          </Col>
        ));
      } else if (col.datatype === 'codetable' && col.mode === 'single') {
        items.push((
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key}>
              {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(
                <Select allowClear={true} onChange={(e) => this.handleSelectChange(e, col)}>
                  {this.getCodeTableOptions(col.codetable)}
                </Select>
              )}
            </Form.Item>
          </Col>
        ));
      } else if (col.datatype === 'codetable' && col.mode === 'radio') {
        const diffNameCol = metadata['dpa_donorAck_diffName'];
        items.push((
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key}>
              {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(
                <Radio.Group onChange={(e) => this.handleRadioChange(e, col)} >
                  {this.getCodeTableOptionsRadio(col, diffNameCol)}
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        ));
      } else if (col.datatype === 'string' && col.mode === 'multiple') {
        items.push(
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key} >
              {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(
                col.key==='conf_govGrant_content' ||
                col.key==='conf_condition_content' ||
                col.key==='conf_personData_content'
                ?
                <TextArea rows={5} disabled />
                :
                <TextArea rows={5}/>
              )}
            </Form.Item>
          </Col>
        );
      } else if (col.datatype === 'string' && col.mode === 'readonly') {
        var dispValue = getFieldValue(col.key);
        if (col.key === 'createdAt' && typeof donDate !== 'undefined') {
          dispValue = dispValue.substring(0,10);
        }
        items.push(
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key} >
              {getFieldDecorator(col.key, { rules: this.getValidationRules(col) })(
                <Descriptions.Item label={t(col.label)}>                      
                  {dispValue}
                </Descriptions.Item>
              )}
            </Form.Item>
          </Col>
        );
      } else if (col.datatype === 'string' && col.mode === 'hidden') {
      } else if (col.datatype === 'decimal') {
        items.push(
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key} >
              {getFieldDecorator(col.key, {
                validateTrigger: getFieldError(col.key) ? ['onChange', 'onBlur'] : "onBlur",
                rules: this.getValidationRules(col) 
              })(
                col.key==='payment_amount'
                ?
                <Input disabled/>
                :
                <Input onBlur={(e) => this.handleDecimalChange(e, col)} />
              )}
            </Form.Item>
          </Col>
        );  
      } else {
        items.push(
          <Col span={12} key={i}>
            <Form.Item className={'two-rows-label'} label={t(col.label)} key={col.key} >
              {getFieldDecorator(col.key, { 
                validateTrigger: 
                  (
                    col.key==='dpa_donorName_Pry' || 
                    col.key==='dpa_orgName_Pry' || 
                    col.key==='dpa_contactPerson_Pry' ||
                    col.key==='dpa_donorAck_diffName'
                  ) 
                  ? ['onChange', 'onBlur'] : "onChange",
                rules: this.getValidationRules(col) 
              })(
                col.key==='donation_id' ||
                col.key==='invoice_no' ||
                col.key==='txnId' ||
                col.key==='payment_method_code' ||
                col.key==='status' ||
                col.key==='transaction_time' ||
                col.key==='payment_reference_id' ||
                col.key==='event_id'
                ?
                <Input disabled/>
                :
                <Input />
              )}
            </Form.Item>
          </Col>
        );
      }
      if (col.key==='createdAt') {
        i++;
        items.push((      
          <Col span={12} key={i}>
            <Form.Item key="updatedBy">
              {getFieldDecorator('updatedBy', {})(<div><p style={{color: "#ffffff", paddingTop: "0px" }}>.</p><Input type="hidden" /></div>)}
            </Form.Item>
          </Col>
        ));
      }
      if (col.key==='di_prjProgAct') {
        i++;
        items.push((      
          <Col span={24} key={i}>
              <div><p style={{fontSize:16, color: "#000000", textAlign: "left", paddingTop: "0px" }}>{t('donation.heading.donPtAck')}</p><br/></div>
          </Col>
        ));          
      }
      if (col.key==='dpa_donorAck_diffName') {
        i++;
        items.push((      
          <Col span={12} key={i}>
            <Form.Item key="version">
              {getFieldDecorator('version', {})(<div><p style={{color: "#ffffff", paddingTop: "0px" }}>.</p><Input type="hidden" /></div>)}
            </Form.Item>
          </Col>
        ));

        i++;
        items.push((      
          <Col span={24} key={i}>
              <div><br/><p style={{fontSize:16, color: "#000000", textAlign: "left", paddingTop: "0px" }}>{t('donation.heading.donConf')}</p><br/></div>
          </Col>
        ));
      }
      if (col.key==='conf_isAck_personData') {
        i++;
        items.push((      
          <Col span={24} key={i}>
              <div><p style={{fontSize:16, color: "#000000", textAlign: "left", paddingTop: "0px" }}>{t('donation.heading.payment')}</p><br/></div>
          </Col>
        ));          
      }

    });
    return items;
  }

  handleDecimalChange = (e, col) => {
    if (e.target.value.trim() !== '') {
      // round to at most 2 decimal places
      var rounded = Math.round(Number(e.target.value) * 100) / 100;
      e.target.value = isNaN(rounded) ? 0 : rounded;
    }
  };

  handleRadioChange = (e, col) => {
    //console.log('radio checked', e.target.value);
    if (col.key==='dpa_donorAckOption') {
      this.setState({
        donorAckValue: e.target.value,
      });
    }
  };

  handleSelectChange = (value, col) => {
    if (col.key==='di_purposeOfDonation') {
      this.setState({
        podValue: value,
      });
    }
    if (col.key==='di_pod_acad_opt') {
      this.setState({
        podAcadValue: value,
      });
    }
    if (col.key==='dpa_typeOfDonor') {
      this.setState({
        typeOfDonorValue: value,
      });
    }
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { getFieldValue, getFieldDecorator } = this.props.form;
    const { metadata, visible, mode, onCancel, onCreate, onUpdate } = this.props;

    if (!metadata) { return; }
    var refId;
    Object.keys(metadata).forEach((colkey) => {
      const col = metadata[colkey];
      if (col.key === 'donation_id' ) {
        refId = getFieldValue(colkey);
      }
    });

    return (
      <Modal title={/*(mode === 'create' ? t('general.add') : t('general.edit')) + ' ' + t(functionLabel)*/'Online Donation Form (Reference ID: ' + refId + ')'}
        width={1000}
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button 
            //style={{ background: "#A02337", borderColor: "#A02337" }} 
            key="submit" type="primary" onClick={mode === 'create' ? onCreate : onUpdate}
          >
            {mode === 'create' ? t('general.add') : t('general.edit')}
          </Button>,
          <Button key="back" onClick={onCancel}>{t('general.cancel')}</Button>
        ]}>

        <Form {...formItemLayout} labelAlign="left">
          <Row gutter={24} buttom="xs">
            {getFieldDecorator('key', {})(<Input type="hidden" />)}
            {this.renderFormItems()}
          </Row>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const entityName = state.dashboard.currentFunction.component_param;
  return {
    entityName: entityName, // eslint-disable-line object-shorthand
    codetable: state.codetable,
    functionLabel: state.dashboard.currentFunction.label,
    metadata: state.data.metadata[entityName],
  };
};

const EditDonationForm = Form.create({ name: 'entityUpdate' })(EditDonationFormImpl);
export default connect(mapStateToProps, null)(EditDonationForm);

import axios from 'axios';
import logger from './_Logger';

const http = {

  get: async (url) => {
    try {
      logger.getLogger('HTTP-Util').debug('HTTP Get: ' + url);
      const response = await axios.get(url, {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        validateStatus: (status) => ((status >= 200 && status < 300) || status === 403 || status === 500),
      });
      logger.getLogger('HTTP-Util').debug(response);
      return response.data;
    } catch (err) {
      logger.getLogger('HTTP-Util').error(err);
      return { result: 'err.client.netConn', error: err.message };
    }
  },

  post: async (url, param) => {
    try {
      logger.getLogger('HTTP-Util').debug('HTTP Post: ' + url);
      logger.getLogger('HTTP-Util').debug(param);
      const response = await axios.post(url, param, {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        validateStatus: (status) => ((status >= 200 && status < 300) || status === 403 || status === 500),
      });
      logger.getLogger('HTTP-Util').debug(response);
      return response.data;
    } catch (err) {
      logger.getLogger('HTTP-Util').error(err);
      return { result: 'err.client.netConn', error: err.message };
    }
  },
};

export default http;

import React from 'react';
import { connect } from 'react-redux';
import { Icon, Col, Row, Card, Layout } from 'antd';
import { t } from '../../../framework';
import { navigateAction } from '../../../redux/actions';
import EntityDataTable from '../../shared/EntityDataTable';
import ApplicationMonitor from '../../shared/ApplicationMonitor';
import ManageDonation from '../../shared/ManageDonation';
import ViewDonation from '../../shared/ViewDonation';
//import Donation from '../../shared/Donation';

const { Content, Footer } = Layout;

class MainContent extends React.Component {

  components = {
    EntityDataTable: EntityDataTable,
    ApplicationMonitor: ApplicationMonitor,
    ManageDonation: ManageDonation,
    ViewDonation: ViewDonation,
    //Donation: Donation
  }

  handleNavigate = (e) => {
    const { navigate, permissions } = this.props;
    let toFunction = permissions;
    if (e.key !== 'home') { toFunction = (e.item.subfunc && e.item.subfunc.length > 0) ? e.item.subfunc : e.item; }
    navigate(toFunction);
  }

  renderCards = () => {
    const { permissions, currentFunction } = this.props;
    const homeItem = (
      <Col sm={12} md={10} lg={6} xl={4} key="home">
        <Card title={t("funcLabel.home")}
          hoverable={true}
          onClick={(e) => this.handleNavigate({ key: 'home', domEvent: e, item: permissions })}>
          <Icon type="home" />
        </Card>
      </Col>
    );
    const cardItems = currentFunction.map((item, key) => {
      if ((permissions === currentFunction && !item.parent_uid) ||
        (permissions !== currentFunction && item.subfunc.length === 0)) {
        return (<Col sm={12} md={10} lg={6} xl={4} key={item.key}>
          <Card title={t(item.label)}
            hoverable={true}
            onClick={(e) => this.handleNavigate({ key: item.key, domEvent: e, item: currentFunction[key] })}>
            <Icon type={item.icon} />
          </Card>
        </Col>);
      } else {
        return '';
      }
    });
    return (
      <Row gutter={24}>
        {(permissions !== currentFunction) ? homeItem : ''}
        {cardItems}
      </Row>
    )
  }

  render() {
    const { currentFunction } = this.props;
    const Tag = this.components[currentFunction.component];
    return (
      <Content>
        {Array.isArray(currentFunction) ? this.renderCards() : <Tag />}
        <Footer>{t('general.copyright')}</Footer>
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.session.lang,
    permissions: state.session.permissions,
    currentFunction: state.dashboard.currentFunction ? state.dashboard.currentFunction : state.session.permissions
  };
};

const mapDispatchToProps = (dispatch) => ({
  navigate: (toFunction) => {
    dispatch(navigateAction(toFunction));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);

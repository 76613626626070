import React from 'react';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { connect } from 'react-redux';
import { Layout, Tag, Divider, Table, Typography, Popconfirm, Icon, Input, Button, PageHeader } from 'antd';
import { entityListAction, entityCreateAction, entityUpdateAction, entityDeleteAction, navigateAction } from '../../../redux/actions';
import { t, logger, md5 } from '../../../framework';
import EntityDataForm from './EntityDataForm';
import EditDonationForm from '../ManageDonation/EditDonationForm';

const { Title } = Typography;
const { Content } = Layout;

class EntityDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      formVisible: false,
      donFormVisible: false,
      tableKey: 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.result === '0' && !props.data) {
      const { listRecord, entityName, csrf } = props;
      listRecord(entityName, csrf);
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentFunction.name !== prevProps.currentFunction.name) {
      this.setState({ searchText: '', tableKey: this.state.tableKey + 1 }); //reset filter
    }
  }

  genColumnSorter(dataType, key) {
    if (dataType === 'date') {
      return (a, b) => { return Date.parse(a.expiryDate) - Date.parse(b.expiryDate); };
    } else if (dataType === 'string' || dataType === 'email') {
      return (a, b) => {
        if (key === 'email') {
          return (a.email==null) ? null : a.email.localeCompare(b.email);
        } else if (key === 'expiryDate') {
          return (a.expiryDate==null) ? null : a.expiryDate.localeCompare(b.expiryDate);
        } else if (key === 'label') {
          return (a.label==null) ? null : a.label.localeCompare(b.label);
        } else if (key === 'icon') {
          return (a.icon==null) ? null : a.icon.localeCompare(b.icon);
        } else if (key === 'DECODE_ID') {
          return (a.DECODE_ID==null) ? null : a.DECODE_ID.localeCompare(b.DECODE_ID);
        } else if (key === 'createdAtStr') {
          return (a.createdAtStr==null) ? null : a.createdAtStr.localeCompare(b.createdAtStr);
        } else if (key === 'di_purposeOfDonation') {
          return (a.di_purposeOfDonation==null) ? null : a.di_purposeOfDonation.localeCompare(b.di_purposeOfDonation);
        } else if (key === 'di_prjProgAct') {
          return (a.di_prjProgAct==null) ? null : a.di_prjProgAct.localeCompare(b.di_prjProgAct);
        } else if (key === 'di_typeOfDonation') {
          return (a.di_typeOfDonation==null) ? null : a.di_typeOfDonation.localeCompare(b.di_typeOfDonation);
        } else if (key === 'status') {
          return (a.status==null) ? null : a.status.localeCompare(b.status);
        } else if (key === 'txnId') {
          return (a.txnId==null) ? null : a.txnId.localeCompare(b.txnId);
        } else if (key === 'payment_reference_id') {
          return (a.payment_reference_id==null) ? null : a.payment_reference_id.localeCompare(b.payment_reference_id);
        } else if (key === 'payment_method_code') {
          return (a.payment_method_code==null) ? null : a.payment_method_code.localeCompare(b.payment_method_code);
        } else if (key === 'invoice_no') {
          return (a.invoice_no==null) ? null : a.invoice_no.localeCompare(b.invoice_no);
        } else if (key === 'event_id') {
          return (a.event_id==null) ? null : a.event_id.localeCompare(b.event_id);
        } else {
          return (a.name==null) ? null : a.name.localeCompare(b.name);
        }
      };
    } else if (dataType === 'integer' || dataType === 'decimal') {
      return (a, b) => {
        if (key === 'di_donationAmount') {
          return a.di_donationAmount - b.di_donationAmount;
        } else if (key === 'donation_id') {
          return a.donation_id - b.donation_id;
        } else if (key === 'payment_amount') {
          return a.payment_amount - b.payment_amount;
        } else {
          return null;
        }
      };
    }
  };

  genColumnSearchProps(dataIndex)  {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => { this.searchInput = node }}
            placeholder={t('general.inputKeyword')}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}>
            {t('general.search')}
          </Button>
          <Button onClick={() => this.cancelSearch(clearFilters)} size="small" style={{ width: 90 }}>
            {t('general.reset')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        var recordValue = (record[dataIndex]===null)?"":record[dataIndex].toString();
        return recordValue.toLowerCase().includes(value.toLowerCase())
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible) setTimeout(() => this.searchInput.select());
      },
      render: text => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ),
    }
  };

  genTableDefinitions() {
    let defs = [];
    const { metadata, codetable, functionLabel } = this.props;
    if (!metadata) { return; }
    for (var key in metadata) {
      const col = metadata[key];
      if (
        functionLabel==='funcLabel.user' &&
        (col.key === 'passwd')
      ) continue;
      if (
        functionLabel==='funcLabel.decode' &&
        (col.key === 'password' || col.key === 'confirm')
      ) continue;
      if (
        functionLabel==='funcLabel.aadoDon' &&
        (col.key === 'createdAt')
      ) continue;
      let def = { key: col.key, title: t(col.label), dataIndex: col.key };
      if (col.sortable) { def.sorter = this.genColumnSorter(col.datatype, col.key); def.sortDirections = ['descend', 'ascend']; }
      if (col.searchable) { def = Object.assign(def, { ...this.genColumnSearchProps(col.key) }); }
      if (col.datatype === 'codetable' && col.mode === 'multiple' && codetable[col.codetable]) {
        def.render = (items) => (<span>
          {items && items.map(item => (
            <Tag
              color='blue'
              //color='#A02337'
              key={item}>
              {codetable[col.codetable].filter(ref => ref.key === parseInt(item))[0].name}
            </Tag>)
          )}
        </span>)
      }
      if (col.datatype === 'codetable' && col.mode === 'single' && codetable[col.codetable]) {
        def.render = (item) => {
          return item && (<span>
            <Tag
              color='blue'
              //color='#A02337'
              key={item}>
              {
                functionLabel==='funcLabel.aadoDon'
                ?
                item
                :
                codetable[col.codetable].filter(ref => ref.key === parseInt(item))[0].name
              }
            </Tag>
          </span>);
        }
      }
      if (col.datatype === 'password') {
        def.render = (item) => {
          return item && (<span>***************</span>);
        }
      }
      if (col.datatype === 'datetime') {
        def.render = (item) => {
          return item && new Date(Date.parse(item)).toLocaleString("en-US");
        }
      }
      if (col.datatype === 'boolean') {
        def.render = (item) => {
          return item===null ? '' : item ? 'Y' : 'N';
        }
      }
      defs.push(def);
    }
    defs.push({ key: 'action', title: t('function.action'), render: this.genRecordActionRender() });
    logger.getLogger('EntityDataTable').debug(defs);
    return defs;
  }

  genRecordActionRender() {
    const { entityName, functionLabel } = this.props;
    return (text, record) => {
      return (
        <span>
          <Button
            type="link"
            onClick={() => {
              let values = {};
              const { metadata } = this.props;
              for (var colname in record) {
                if (colname === 'key' || colname === 'version') {
                  values[colname] = { value: record[colname] };
                } else if (!metadata[colname]) {
                  continue;
                } else if (!metadata[colname].editable) {
                  continue;
                } else if (metadata[colname].datatype === 'date') {
                  values[colname] = { value: moment(record[colname]) };
                } else if (metadata[colname].datatype === 'password') {
                  values[colname] = { value: '{md5}' + record[colname] };
                } else if ( metadata[colname].datatype === 'codetable' && metadata[colname].mode === 'single') {
                  values[colname] = { value: record[colname] ? record[colname].toString() : '' };
                } else {
                  values[colname] = { value: record[colname] };
                }
              }
              logger.getLogger('EntityDataTable').debug(values);
              this.formRef.props.form.setFields(values);
              if (entityName==='aadodonation')
                this.setState({ donFormVisible: true, formMode: 'update' });
              else
                this.setState({ formVisible: true, formMode: 'update' });
            }}>
            {t('general.edit')}
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title={
              t('general.confirmDel') + " '"
              + (
                functionLabel==='funcLabel.decode' 
                ? 
                record.DECODE_ID 
                : 
                functionLabel==='funcLabel.aadoDon' 
                ? 
                record.donation_id
                :
                record.name 
              )          
              + "'"
            }
            okText={t('general.confirm')}
            cancelText={t('general.cancel')}
            onConfirm={() => this.handleDelete(record.key)}
            icon={<Icon type="question-circle-o"
              style={{ color: 'red' }} />}>
            <Button type="link">
              {t('general.delete')}
            </Button>
          </Popconfirm>
        </span>
      );
    }
  }

  handleCreate = () => {
    this.formRef.props.form.validateFields((err, fieldsValue) => {
      if (!err) {
        const { createRecord, metadata, entityName, csrf } = this.props;
        const values = Object.assign({}, fieldsValue);
        for (var colname in values) {
          if (!metadata[colname]) {
            continue;
          } else if (metadata[colname].datatype === 'date') {
            values[colname] = fieldsValue[colname].format('YYYY-MM-DD');
          } else if (metadata[colname].datatype === 'password') {
            values[colname] = md5.encrypt(fieldsValue[colname]);
          } else if (metadata[colname].datatype === 'boolean') {
            values[colname] = fieldsValue[colname]==='' ? null : fieldsValue[colname];
          }
        }
        createRecord(entityName, values, csrf);
        this.setState({ formVisible: false });
        this.setState({ donFormVisible: false });
      }
    });
  }

  handleUpdate = () => {
    this.formRef.props.form.validateFields((err, fieldsValue) => {
      if (!err) {
        const { updateRecord, metadata, entityName, csrf } = this.props;
        const values = Object.assign({}, fieldsValue);        
        for (var colname in values) {
          if (!metadata[colname]) {
            continue;
          } else if (metadata[colname].datatype === 'date') {
            values[colname] = fieldsValue[colname].format('YYYY-MM-DD');
          } else if (metadata[colname].datatype === 'password') {
            if (fieldsValue[colname].startsWith('{md5}')) {
              values[colname] = fieldsValue[colname].substring(5);
            } else {
              values[colname] = md5.encrypt(fieldsValue[colname]);
            }
          }
        }
        updateRecord(entityName, values.key, values, csrf);
        this.setState({ formVisible: false });
        this.setState({ donFormVisible: false });
      }
    });
  }

  cancelCreateUpdate = () => {
    this.setState({ formVisible: false });
    this.setState({ donFormVisible: false });
  }

  handleDelete = (recordKey) => {
    const { deleteRecord, entityName, csrf } = this.props;
    deleteRecord(entityName, recordKey, csrf);
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  cancelSearch = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleBack = () => {
    const { permissions, currentFunction, navigate } = this.props;
    let toFunctions = permissions.filter(item => item.key === currentFunction.parent_uid);
    navigate(toFunctions.length === 0 ? permissions : toFunctions[0].subfunc, false);
  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { entityName, functionLabel, functionIcon, data } = this.props;
    const items = [];
    if (entityName==='aadodonation') {
      items.push((
        <EditDonationForm
          wrappedComponentRef={this.saveFormRef}
          mode={this.state.formMode}
          visible={this.state.donFormVisible}
          onCreate={this.handleCreate}
          onUpdate={this.handleUpdate}
          onCancel={this.cancelCreateUpdate} />
      ));
    } else {
      items.push((
        <EntityDataForm
          wrappedComponentRef={this.saveFormRef}
          mode={this.state.formMode}
          visible={this.state.formVisible}
          onCreate={this.handleCreate}
          onUpdate={this.handleUpdate}
          onCancel={this.cancelCreateUpdate} />
      ));
    }
    items.push((
      <Content>
        <PageHeader
          onBack={this.handleBack}
          title={<Title level={4}><Icon type={functionIcon} />&nbsp;{t(functionLabel)}</Title>}
          extra={
            entityName==='aadodonation'
            ?
            null
            :
            <Button type="primary" onClick={() => {
              let values = {};
              const { metadata } = this.props;
              for (var colname in metadata) {
                if (!metadata[colname]) {
                  continue;
                } else if (!metadata[colname].editable) {
                  continue;
                } else if (metadata[colname].datatype === 'date') {
                  values[colname] = { value: moment() };
                } else if (metadata[colname].datatype === 'codetable' && metadata[colname].mode === 'multiple') {
                  values[colname] = { value: [] };
                } else {
                  values[colname] = { value: '' };
                }
              }
              logger.getLogger('EntityDataTable').debug(values);
              this.formRef.props.form.setFields(values);
              this.setState({ formVisible: true, formMode: 'create' });
            }}><Icon type="plus" />{t('general.add') + ' ' + t(functionLabel)}</Button>
          } />
        <Table key={this.state.tableKey} columns={this.genTableDefinitions()} dataSource={data} />
      </Content>
    ));
    return (<Content>{items}</Content>);
  }
}

const mapStateToProps = (state) => {
  let entityName = state.dashboard.currentFunction.component_param;
  return {
    entityName: entityName,
    permissions: state.session.permissions,
    currentFunction: state.dashboard.currentFunction,
    functionIcon: state.dashboard.currentFunction.icon,
    functionLabel: state.dashboard.currentFunction.label,
    metadata: state.data.metadata[entityName],
    lang: state.session.lang,
    codetable: state.codetable,
    data: state.data.data,
    action: state.action.type,
    result: state.action.result,
    csrf: state.action.csrf
  };
};

const mapDispatchToProps = (dispatch) => ({
  navigate: (toFunctions) => {
    dispatch(navigateAction(toFunctions));
  },
  listRecord: async (entityName, csrf) => {
    await dispatch(entityListAction(entityName, csrf));
  },
  createRecord: (entityName, data, csrf) => {
    dispatch(entityCreateAction(entityName, data, csrf));
  },
  updateRecord: (entityName, key, data, csrf) => {
    dispatch(entityUpdateAction(entityName, key, data, csrf));
  },
  deleteRecord: (entityName, key, csrf) => {
    dispatch(entityDeleteAction(entityName, key, csrf));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityDataTable);

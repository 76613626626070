import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Switch } from 'react-router-dom';

// components
import { PageNotFound, LoginForm, RegistrationForm, Dashboard } from './components';

// framework
import { history, logger, redux, PublicRoute, ProtectedRoute } from './framework';

// application
const App = () => (
  <Provider store={redux.getStore()}>
    <Router history={history}>
      <Switch>
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <PublicRoute path="/auth/adfs/login" exact component={() => { window.location.href = process.env.REACT_APP_ADFS_LOGIN_URL; }} />
        <PublicRoute path="/auth/adfs/logout" exact component={() => { window.location.href = process.env.REACT_APP_ADFS_LOGOUT_URL; }} />
        <PublicRoute path="/register" exact component={RegistrationForm} />
        <PublicRoute path="/" exact component={LoginForm} />
        <PublicRoute component={PageNotFound} />
      </Switch>
    </Router>
  </Provider>
);

logger.getLogger('root').info(process.env.NODE_ENV + ' mode running');
ReactDOM.render(App(), document.getElementById('root'));

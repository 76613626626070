import moment from 'moment';

export default (state = { metadata: undefined, data: [] }, action) => {
  let newState = state;
  switch (action.type) {
    case 'AUTHENTICATE-SUCCESS': {
      const { entities } = action.payload.value;
      newState = Object.assign({}, newState, { metadata: entities });
      break;
    }
    case 'NAVIGATE':
      newState = Object.assign({}, newState, { data: null });
      break;
    case 'DATA-REFRESH-SUCCESS':
      var r = action.payload.value;
      const donIdInt = Number.parseInt(r.id, 10);
      state.data.find(x => x.donation_id === donIdInt).txnId = r.transactionid;
      state.data.find(x => x.donation_id === donIdInt).payment_method_code = r.paymentmethodcode ? r.paymentmethodcode.substring(r.paymentmethodcode.lastIndexOf(".") + 1) : '';
      state.data.find(x => x.donation_id === donIdInt).payment_amount = r.amount;
      state.data.find(x => x.donation_id === donIdInt).status = r.status ? r.status.toUpperCase()==='PAID' ? 'Successful' : 'Unsuccessful' : '';
      state.data.find(x => x.donation_id === donIdInt).transaction_time = moment(new Date(r.transactiontime)).format('YYYY-MM-DD HH:mm');
      //​​​​r.search, r.paymentreferenceid, r.eventid
      newState = Object.assign({}, newState, { data: state.data });
      break;
    case 'DATA-LIST-SUCCESS':
      newState = Object.assign({}, newState, { data: action.payload.value });
      break;
    case 'DATA-CREATE-SUCCESS':
    case 'DATA-UPDATE-SUCCESS':
    case 'DATA-DELETE-SUCCESS':
    default:
      newState = state;
      break;
  }
  return newState;
};

import React from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Icon } from 'antd';

import { t } from '../../../framework';
import { navigateAction } from '../../../redux/actions';

const { SubMenu } = Menu;
const { Sider } = Layout;

class SideMenu extends React.Component {

  onClick = e => {
    const { permissions, navigate } = this.props;
    let toFunction = permissions;
    if (e.key !== 'home') { toFunction = permissions.filter(item => item.key.toString() === e.key)[0] }
    navigate(toFunction);
  }

  render() {
    const { permissions } = this.props;
    const siderItems = permissions.map((func) => {
      if (!func.parent_uid && func.subfunc.length > 0) {
        return (<SubMenu key={func.key} title={<div><Icon type={func.icon} /><span>{t(func.label)}</span></div>}>
          {
            func.subfunc.map((subfunc) => {
              return (<Menu.Item key={subfunc.key}>
                <span><Icon type={subfunc.icon} />{t(subfunc.label)}</span>
              </Menu.Item>);
            })
          }
        </SubMenu>);
      } else if (!func.parent_uid) {
        return (<Menu.Item key={func.key}>
          <Icon type={func.icon} /><span>{t(func.label)}</span>
        </Menu.Item>);
      } else {
        return '';
      }
    });
    return (
      <Sider className="sider" breakpoint="sm" collapsedWidth="80">
        <Menu mode="inline" onClick={this.onClick} selectable={false}>
          <Menu.Item key="home"><Icon type="home" /><span>{t('funcLabel.home')}</span></Menu.Item>
          {siderItems}
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.session.lang,
    permissions: state.session.permissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  navigate: (toFunctions) => {
    dispatch(navigateAction(toFunctions));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);

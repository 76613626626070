import { store } from './_ReduxHelper';
import logger from './_Logger';
import * as label from '../i18n';

export default (id) => {
  const state = store.getState();
  const lang = state.session.lang ? state.session.lang : 'en_US';
  if (label[lang] && label[lang][id]) {
    return label[lang][id];
  } else {
    logger.getLogger('i18n-Util').error('UNKNOWN LABEL ID: ' + id + '@' + state.session.lang);
    return '*UNKNOWN LABEL*';
  }
};

import './Dashboard.less';
import React from 'react';
import { connect } from 'react-redux';
import { Layout, Spin, message } from 'antd';
import { t, logger } from '../../../framework';
import { navigateAction } from '../../../redux/actions';
import Container from '../../shared/Container';
import TopMenu from './TopMenu';
import SideMenu from './SideMenu';
import MainContent from './MainContent';

class Dashboard extends React.Component {

  state = {};

  static getDerivedStateFromProps(props, state) {
    //alert(JSON.stringify(props));
    if (props.result !== '0') {
      message.error(t(props.result));
      props.reset();
    } else if (props.action === 'DATA-CREATE-SUCCESS') {
      message.info(t('general.createSuc'));
    } else if (props.action === 'DATA-UPDATE-SUCCESS') {
      message.info(t('general.updateSuc'));
    } else if (props.action === 'DATA-DELETE-SUCCESS') {
      message.info(t('general.deleteSuc'));
    } else if (props.action === 'DATA-REFRESH-SUCCESS') {
      message.info(t('general.refreshSuc'));
    } else if (props.action === 'EMAIL-SEND-SUCCESS') {
      message.info(t('general.emailSuc'));
    }
    return state;
  }	

  componentDidMount() {
    const { navigate, location, permissions } = this.props;
    var func = undefined;
    // determine function
    let requestFunction = location.pathname.split('/').filter(item => item && item.length>0);
    if (requestFunction[0] === 'dashboard') {
      if (requestFunction.length >= 2) {
        var funcLv2 = permissions.filter(item => item.name === requestFunction[1])[0];
        if (funcLv2 && requestFunction.length >= 3) {
          var funcLv3 = funcLv2.subfunc.filter(item => item.name === requestFunction[2])[0];
          func = funcLv3;
        } else {
          func = funcLv2;
        }
      }
    }
    // naviage to function
    logger.getLogger('Dashboard').debug('URL redirect to ' + JSON.stringify(func));
    if (!func || func.length === 0) {			
      navigate(permissions);
    } else {
      func.subfunc.length > 0 ? navigate(func.subfunc) : navigate(func);
    }
  }

  render() {
    const { loading } = this.props;
    return (
      <Spin spinning={loading} size="large" tip={t('general.loading')}>
        <Container>
          <Layout className="dashboard">
            <TopMenu />
            <Layout>
              <SideMenu />
              <Layout className="main">
                <MainContent />
              </Layout>
            </Layout>
          </Layout>
        </Container>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.dashboard.loadingCount !== 0,
    permissions: state.session.permissions,
    action: state.action.type,
    result: state.action.result
  };
};

const mapDispatchToProps = (dispatch) => ({
  reset: () => {
    dispatch({ type: 'RESET-RESULT' });
  },	
  navigate: (toFunctions) => {
    dispatch(navigateAction(toFunctions));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import './PageNotFound.less';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button, Card } from 'antd';

const { Meta } = Card;

class PageNotFound extends React.Component {

  render() {
    return (
      <Card bordered={true} className="page-not-found">
        <Meta title="404 PAGE NOT FOUND" description="We're sorry! The page your requested does not exist" className="title" />
        <Icon type="stop" />
        <Link to={'/'}><Button type="link" htmlType="submit" className="action">{'<< Return To Home Page >>'}</Button></Link>
      </Card>
    );
  }
}

export default PageNotFound;

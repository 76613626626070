export default (state = { name: '', email: '', permissions: [], lang: 'enUS' }, action) => {
  let newState = state;
  switch (action.type) {
    case 'LOGIN-SUCCESS': {
      const { name, email } = action.payload.value;
      newState = Object.assign({}, newState, { name, email, permissions: [], lang: 'enUS' });
      break;
    }
    case 'AUTHENTICATE-SUCCESS': {
      const { permissions } = action.payload.value;
      permissions.forEach((element) => {
        if (!element.parent_uid) {
          element.backfuncName = '';
          element.subfunc = permissions
            .map((func) => (func.parent_uid === element.key ? func : undefined))
            .filter((item) => item !== undefined);
        } else {
          element.backfuncName = permissions.filter((item) => item.key === element.parent_uid)[0].name;
          element.subfunc = [];
        }
      });
      newState = Object.assign({}, newState, { name: action.payload.value.name, email: action.payload.value.email, permissions });
      break;
    }
    case 'LOGIN-ERROR':
    case 'AUTHENTICATE-ERROR':
      newState = { name: '', email: '', permissions: [], lang: 'enUS' };
      break;
    case 'LOGOUT-SUCCESS':
      newState = { name: '', email: '', permissions: [], lang: 'enUS' };
      break;
    case 'SWITCH-LANG':
      newState = Object.assign({}, newState, { lang: action.payload });
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};
